const initialState = () => {
  return {
    airportList: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    airportList(state) {
      return state.airportList;
    },
  },
  mutations: {
    setList(state, payload) {
      state.airportList = payload;
    },
    addNew(state, payload) {
      state.airportList = [...state.airportList, payload];
    },
    remove(state, recordID) {
      const idx = state.airportList.findIndex((item) => item.id == recordID);
      if (idx != -1) {
        state.airportList.splice(idx, 1);
      }
    },
    updateField(state, payload) {
      const recordID = payload["id"];
      const field = payload["field"] || {};

      const recordIndex = state.airportList.findIndex(
        (item) => item.id == recordID
      );
      if (recordIndex != -1) {
        const selectedRecord = state.airportList[recordIndex];
        const newState = {
          ...selectedRecord,
          ...field,
        };
        newState.status = newState.is_active == "1" ? "active" : "inactive";

        //Update State
        Object.assign(state.airportList[recordIndex], newState);
      }
    },
    updateNote(state, payload) {
      const recordID = payload["id"];
      const comments = payload["comments"];

      const recordIndex = state.airportList.findIndex(
        (item) => item.id == recordID
      );

      if (recordIndex != -1) {
        const selectedRecord = state.airportList[recordIndex];

        selectedRecord.comments = [...comments];
      }
    },
  },
  actions: {},
};

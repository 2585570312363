import { APP_CONFIG } from "@/config";
import { DateTime } from "../utils";

export class ChatModel {
  constructor(user, message, idx) {
    this.chatUser = new ChatUser(user, message.id);
    this.chatMessage = new ChatMessage(message, idx);
  }
}

class ChatUser {
  constructor(user, firebaseUserID) {
    this.id = firebaseUserID;

    this.isSupportUser =
      APP_CONFIG.firebase.support.firebaseUid == firebaseUserID;

    this.firebase_id = firebaseUserID;

    this.full_name = this.isSupportUser
      ? APP_CONFIG.firebase.support.displayName
      : user.full_name;

    // this.user_type_id = user.user_type_id;

    this.defaultImg = this.isSupportUser
      ? require("@assets/img/support-user.png")
      : require("@assets/img/chat-user.png");
  }
}

class ChatMessage {
  constructor(chatMessage, idx) {
    this.idx = idx;
    this.id = chatMessage.id;
    this.message = chatMessage.message;
    this.time = chatMessage.time;
    this.read = chatMessage.read;
    this.imageUrl = chatMessage.imageUrl || null;

    //const timestamp = DateTime.parseDate(chatMessage.time.toMillis()).unix();
    const parsedDate = DateTime.parseDate(chatMessage.time.toMillis());
    this.formattedDate = parsedDate.format("DD/MM/YYYY");
    this.formattedTime = parsedDate.format("hh:mm A");
  }
}

<template>
  <section
    v-if="selectedUserMessages && selectedUserMessages.user"
    id="chat-messager-container"
  >
    <div class="chat-message-header border-bottom d-none d-lg-block">
      <div class="d-flex align-items-center py-1">
        <div class="position-relative">
          <img
            src="@assets/img/chat-user.png"
            class="rounded-circle mr-1"
            alt="Sharon Lessman"
            width="40"
            height="40"
          />
        </div>
        <div class="flex-grow-1 pl-3 username-heading">
          <strong>{{ selectedUserMessages.user.full_name }}</strong>
          <!-- <div class="text-muted small"><em>Typing...</em></div> -->
        </div>
        <div>
          <!-- <button class="btn btn-primary btn-lg mr-1 px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-phone feather-lg"
            >
              <path
                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
              ></path>
            </svg>
          </button> -->
        </div>
      </div>
    </div>

    <div class="position-relative">
      <perfect-scrollbar ref="chatMessagScrollRef">
        <div
          ref="chatMsgContainer"
          class="chat-messages p-4 chat-msg-exchanger"
        >
          <slide-y-down-transition :duration="500" group>
            <div
              v-for="(chat, index) in selectedUserMessages.messages"
              :key="chat.chatMessage.idx"
              :index="index"
              class="chat-message-main"
              :class="{
                'chat-message-right': chat.chatUser.isSupportUser,
                'chat-message-left': !chat.chatUser.isSupportUser,
              }"
            >
              <div>
                <img
                  :src="chat.chatUser.defaultImg"
                  class="rounded-circle mr-1"
                  width="40"
                  height="40"
                />
                <div class="text-muted small text-nowrap mt-2">
                  {{ chat.chatMessage.formattedTime }}
                </div>
              </div>
              <div class="txt-wrapper">
                <div class="flex-shrink-1 txt-content-wrapper">
                  <div class="font-weight-bold user-message--title">
                    {{ chat.chatUser.full_name }}
                  </div>
                  <div class="txt-content">
                    {{ chat.chatMessage.message }}
                    <div
                      v-if="chat.chatMessage.imageUrl"
                      class="txt-content-img"
                    >
                      <img :src="chat.chatMessage.imageUrl" />
                    </div>
                  </div>
                </div>
                <div class="txt-date">{{ chat.chatMessage.formattedDate }}</div>
              </div>
            </div>
          </slide-y-down-transition>
        </div>
      </perfect-scrollbar>
    </div>

    <div class="flex-grow-0 py-3 px-4 border-top text-msg-container">
      <div class="input-group">
        <!-- <div class="img-msg-container">
          <div class="img-msg_content" @click="onUploadImg">
            <span class="fas fa-image"></span>
            <input
              ref="chatImgIinput"
              type="file"
              accept="image/*"
              class="chat-img-upload-input"
            />
          </div>
        </div> -->
        <input
          v-model="textMessage"
          type="text"
          class="form-control chat--input"
          placeholder="Type your message"
          @keyup.enter="sendMessage"
        />
        <button
          :disabled="isInputDisabled"
          class="btn btn-primary chat--input-button"
          @click="sendMessage"
        >
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </section>
  <section v-else id="chat-messager-container" class="no-chat-selection">
    <div class="no-chat-selection_heading">LIVE SUPPORT</div>

    <div class="no-chat-selection_img">
      <img src="@assets/img/pngegg.png" />
    </div>
    <div class="no-chat-selection_txt">
      Chat Session With Customer Support Now!
    </div>
  </section>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapGetters } from "vuex";

import { firebaseService } from "@/core/services";
import { Helper } from "@/core/utils";

export default {
  name: "ChatMessage",
  components: {
    SlideYDownTransition,
    PerfectScrollbar,
  },
  data() {
    return {
      textMessage: null,
    };
  },

  computed: {
    ...mapGetters("chat", ["selectedUserMessages"]),
    supportUserID() {
      return firebaseService.userUID;
    },
    isInputDisabled() {
      return Helper.trim(this.textMessage) ? false : true;
    },
    chatImgInput() {
      return this.$refs.chatImgIinput;
    },
  },
  mounted() {
    this.scrollBottom();
  },
  methods: {
    sendMessage() {
      /* console.log(
        "--------------------->",
        firebaseService.firestore
          .collection("support_chats")
          .doc("3eUicLv40ygc17oGld1jsZvnbXg1")
          .collection("messages")
          .add()
      ); */
      /* console.log("data", data);
      firebaseService.firestore
        .collection("support_chats")
        .doc("3eUicLv40ygc17oGld1jsZvnbXg1")
        .collection("messages")
        .add(data)
        .then((doc) => {
          console.log("doc", doc);
        }); */
      const textMsg = Helper.trim(this.textMessage);

      if (!textMsg) {
        return;
      }

      this.textMessage = "";

      firebaseService
        .sendMessage({
          message: textMsg,
          sendTo: this.selectedUserMessages.user.firebase_id,
        })
        .then((_ctx) => {
          this.scrollBottom();
        });
    },
    onUploadImg() {
      console.dir(this.chatImgInput);
      if (this.chatImgInput && this.chatImgInput.click) {
        this.chatImgInput.click();
      }
    },
    scrollBottom() {
      this.$store.dispatch("chat/scrollToLatestMessage");
    },
  },
};
</script>

<style lang="scss"></style>

import { BaseService } from "./base.service";

import { SettingtModel } from "@/core/models";
import { SETTING } from "@/core/enums";
import { DateTime } from "@/core/utils";

export class SettingService extends BaseService {
  getDetails() {
    return this.get("/settings").then((res) => {
      const state = new SettingtModel();

      if (res.data && Array.isArray(res.data)) {
        this.setInfoPopupUtcTime(res.data);

        res.data.forEach((prefItem) => {
          // console.log("prefItemprefItem", prefItem);
          const selectedState = state[prefItem.category];
          if (selectedState) {
            if (
              Object.prototype.hasOwnProperty.call(selectedState, prefItem.name)
            ) {
              selectedState[prefItem.name] = prefItem.value;
            }
          } else {
            if (["uploads"].indexOf(prefItem.category) != -1) {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.other[prefItem.category],
                  prefItem.name
                )
              ) {
                state.other[prefItem.category][prefItem.name] = prefItem.value;
              }
            }
          }
        });
      }

      this.$store.commit("setting/setDetails", state);

      return {
        ...res,
        data: state,
      };
    });
  }

  saveDetails(payload) {
    const settingType = payload.type;
    const settingData = payload.data;

    const mappedPayload = { [settingType]: settingData };

    return this.patch(`/settings`, mappedPayload).then((res) => {
      this.$store.commit("setting/setDetails", mappedPayload);
      return res;
    });
  }

  saveInfoPopup(payload) {
    const mappedPayload = {
      information_popup: {},
    };
    const fields = [
      { name: "information_popup_start_date", type: "date" },
      { name: "information_popup_end_date", type: "date" },
      {
        name: "information_popup_start_time",
        type: "time",
      },
      {
        name: "information_popup_end_time",
        type: "time",
      },
    ];

    return this.saveDetails(payload).then((res) => {
      this.setInfoPopupUtcTime(res.data);

      fields.forEach((record) => {
        const foundField = res.data.find((item) => item.name == record.name);
        if (foundField) {
          mappedPayload.information_popup[record.name] = foundField.value;
        }
      });

      this.$store.commit("setting/setDetails", mappedPayload);
      return res;
    });
  }

  toggleEdit(type, editable) {
    this.$store.commit("setting/toggleEdit", {
      type: type,
      editable: editable,
    });
  }

  isEditable(type) {
    const isEditable = this.$store.getters["setting/isEditable"];
    return (isEditable && isEditable[type]) || false;
  }

  resetAllEditState() {
    this.$store.commit("setting/resetAllEditMode");
  }

  saveFAQ(payload, id) {
    if (id) {
      return this.put(`/content/faqs/${id}`, payload).then((res) => {
        return res;
      });
    } else {
      return this.post(`/content/faqs`, payload).then((res) => {
        return res;
      });
    }
  }

  getAllFAQ() {
    return this.get(`/content/faqs`).then((res) => {
      return res.data;
    });
  }

  removeFAQ(id) {
    return this.delete(`/content/faqs/${id}`).then((res) => {
      return res.data;
    });
  }
  removeDoc(filename) {
    const otherSettingsModel = this.$store.getters["setting/findDetails"](
      SETTING.OTHER
    ).model;
    console.log(
      "otherSettingsModelotherSettingsModel",
      otherSettingsModel.uploads.safety_cards
    );
    const uploadedFiles = otherSettingsModel.uploads.safety_cards.filter(
      (r1) => r1 != filename.file_name
    );
    otherSettingsModel.uploads.safety_cards = uploadedFiles;

    this.$store.commit("setting/updateOtherSettings", otherSettingsModel);
    console.log(this.$store.getters["setting/findDetails"](SETTING.OTHER));

    return this.patch(`settings/safetyCard/delete`, {
      filename: filename.original_name,
    }).then((res) => {
      return res;
    });
  }

  uploadDoc(file, field, progressCallback) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("category", field);

    return this.post(
      `/settings/upload`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      {
        onUploadProgress: (progressEvent) => {
          progressCallback && progressCallback(progressEvent);
          /* this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        ); */
        },
      }
    ).then((res) => {
      let settingDetails = res.data;
      const filteredData = settingDetails.filter(
        (prefItem) => ["uploads"].indexOf(prefItem.category) != -1
      );
      let mappedPayload = {};

      filteredData.forEach((prefItem) => {
        if (!mappedPayload[prefItem.category]) {
          mappedPayload[prefItem.category] = {};
        }
        mappedPayload[prefItem.category][prefItem.name] = prefItem.value;
      });
      this.$store.commit("setting/updateOtherSettings", mappedPayload);

      return res.data;
    });
  }

  setInfoPopupUtcTime(data) {
    const dateConversionFields = [
      { name: "information_popup_start_date", type: "date" },
      { name: "information_popup_end_date", type: "date" },
      {
        name: "information_popup_start_time",
        type: "time",
        mapTo: "information_popup_start_date",
      },
      {
        name: "information_popup_end_time",
        type: "time",
        mapTo: "information_popup_end_date",
      },
    ];

    dateConversionFields.forEach((record) => {
      const foundField = data.find((item) => item.name == record.name);
      if (foundField) {
        if (record.type == "time") {
          foundField.value = DateTime.parseUtc(foundField.value, "HH:mm");
        } else {
          foundField.value = DateTime.parseUtc(foundField.value, "YYYY-MM-DD");
        }
      }
    });
  }
}

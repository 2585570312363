import BaseModel from "./BaseModel";

export class UsersModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      phone: undefined,
      email: undefined,
      parent_id: undefined,
      email_verified_at: undefined,
      salt: undefined,
      is_active: undefined,
      deleted_at: undefined,
      created_at: undefined,
      updated_at: undefined,
      user_type_id: undefined,
      invited_by: undefined,
      firebase_id: undefined,
      chat_image: undefined,
      fcm_token: undefined,
      full_name: undefined,
      chat_image_url: undefined,
    };
  }
}

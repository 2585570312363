import moment from "moment";

export class Helper {
  static uid() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  static isObject = (item) => {
    return typeof item === "object" && !Array.isArray(item) && item !== null;
  };

  static isArray = (item) => {
    return Array.isArray(item);
  };

  static trim = (str) => {
    if (typeof str == "string") {
      return str && str.trim();
    } else {
      return "";
    }
  };

  static toList(obj) {
    return Object.keys(obj).map(function (key) {
      return obj[key];
    });
  }

  static numberFormat(number, dp, currency) {
    if (isNaN(number)) number = 0;
    if (dp === 0) {
      dp = 0;
    } else if (dp == "" || typeof dp == "undefined") {
      dp = 2;
    }

    let a;
    if (currency && typeof currency == "string") {
      currency = currency ? currency + " " : "";
    } else {
      currency = "";
    }

    if (parseFloat(number) < 0) {
      //a = Number(number).toFixed(dp).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/-/, "($") + ")";
      a =
        Number(number)
          .toFixed(dp)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .replace(/-/, "(") + ")";
    } else {
      a =
        currency +
        Number(number)
          .toFixed(dp)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return a;
  }

  static pad2(n) {
    return n > 9 ? "" + n : "0" + n;
  }

  static getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  static fileNameFromUrl(url) {
    let matches = url.match(/\/([^\\/?#]+)[^\\/]*$/);
    if (!matches) {
      return null;
    }
    if (matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  static textReplace(str, searchText, replaceText) {
    return str.replace(searchText, replaceText);
  }

  static textAllReplace(str, mapObj) {
    const pattern = new RegExp(Object.keys(mapObj).join("|"), "gi");

    return str.replace(pattern, function (matched) {
      return mapObj[matched];
    });
  }

  static sortNestedTree(list) {
    list.sort((a, b) => {
      let from, to;

      if (a.messages.length) {
        from = `${
          a.messages[a.messages.length - 1].chatMessage.formattedDate
        } ${a.messages[a.messages.length - 1].chatMessage.formattedTime}`;
      } else {
        from = 0;
      }

      if (b.messages.length) {
        to = `${b.messages[b.messages.length - 1].chatMessage.formattedDate} ${
          b.messages[b.messages.length - 1].chatMessage.formattedTime
        }`;
      } else {
        to = 0;
      }

      return (
        (to ? moment(to, "DD/MM/YYYY hh:mm A").unix() : 0) -
        (from ? moment(from, "DD/MM/YYYY hh:mm A").unix() : 0)
      );
    });
    return list;
  }

  static toTitleCase(str) {
    let i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }

  static getPropByString(obj, propString) {
    if (!propString) return obj;

    let prop,
      props = propString.split(".");

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];

      var candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  }
}

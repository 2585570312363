/**
 * Create instance, which represent response object
 * @param {Object} [response] - axios response object
 */
export class NetworkResponse {
  constructor(response) {
    this.data = response.data.data || null;
    this.success = response.data.success;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = response.data.message || null;
  }
}

/**
 * Create instance, which represent error object
 * @param {Object} [error] - axios error object
 */
export class NetworkError extends Error {
  constructor(error) {
    super();
    const responseInstance = error.response;

    this.success = responseInstance ? responseInstance.data.success : false;
    //this.meta = responseInstance ? responseInstance.data.meta : false;
    //this.code = responseInstance ? responseInstance.data.code : false;
    this.status = responseInstance ? responseInstance.status : false;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = _getResponseErrorMessage(error);
  }
}

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
  let message = "";
  switch (status) {
    case 200:
      message = "All done. Request successfully executed";
      break;
    case 201:
      message = "Data successfully created";
      break;
    case 400:
      message = "Bad Request";
      break;
    case 401:
      message = "Need auth";
      break;
    case 404:
      message = "Not found";
      break;
    case 503:
      message = "Service unavailable. Try again later";
      break;
    default:
      message = "Something wrong. Client default error message";
      break;
  }
  return message;
}

function _getResponseErrorMessage(error) {
  if (error.response && error.response.data) return error.response.data.message;
  if (error.response && error.response.statusText)
    return error.response.statusText;
  return error.message === "Network Error"
    ? "Oops! Network Error. Try again later"
    : error.message;
}

// import { authService, localStorageService } from "@/core/services";

import { Helper } from "@/core/utils";

const initialState = () => {
  return {
    users: [],
    messages: [],
    selectedUser: null,
    selectedUserMessages: null,
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    userList(state) {
      return state.users;
    },
    userMessageList(state) {
      return state.messages;
    },
    pickedUser(state) {
      return state.selectedUser;
    },
    selectedUserMessages(state) {
      return (
        state.selectedUserMessages || {
          user: null,
          messages: [],
          chatUserUid: state.selectedUser
            ? state.selectedUser.firebase_id
            : null,
          unreadCount: 0,
        }
      );
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    chosenUser(state, user) {
      if (!user) {
        this.commit("chat/clearUserSelection");
      } else {
        state.selectedUser = user;
        state.selectedUserMessages = state.messages.find((chat) => {
          return chat.chatUserUid == user.firebase_id;
        });
      }

      setTimeout(() => {
        this.dispatch("chat/scrollToLatestMessage");
      }, 100);
    },
    setUserMessages(state, chat) {
      // console.log(state.messages);
      if (!chat) {
        return;
      }
      const existUserMessagesIndex = state.messages.findIndex(
        (item) => item.chatUserUid == chat.firebase_id
      );
      if (existUserMessagesIndex != -1) {
        state.messages[existUserMessagesIndex].messages = [
          ...chat.chatMessages,
        ];
        state.messages[existUserMessagesIndex].unreadCount = state.messages[
          existUserMessagesIndex
        ].messages.filter((item) => {
          return item.chatMessage.read === false;
        }).length;
      } else {
        state.messages.push({
          messages: chat.chatMessages || [],
          chatUserUid: chat.firebase_id,
          user: chat.user,
          unreadCount: chat.chatMessages.filter((item) => {
            return item.chatMessage.read === false;
          }).length,
          timer: null,
        });
      }

      this.commit("chat/sortUserByLatest");

      setTimeout(() => {
        this.dispatch("chat/scrollToLatestMessage");
      }, 100);
    },
    clearUserSelection(state) {
      state.selectedUser = null;
      state.selectedUserMessages = null;
    },
    readMarkMessages(state, firebaseUserID) {
      const existUserMessagesIndex = state.messages.findIndex(
        (item) => item.chatUserUid == firebaseUserID
      );
      if (existUserMessagesIndex != -1) {
        state.messages[existUserMessagesIndex].messages = state.messages[
          existUserMessagesIndex
        ].messages.map((item) => {
          item.read = true;
          return item;
        });
      }
    },
    sortUserByLatest(state) {
      Helper.sortNestedTree(state.messages);
      state.users = state.messages.map(function (userMessageInstance) {
        /* const found = state.users.find((userInstance) => {
          console.log("userInstance", userInstance);
          console.log("userMessageInstance", userMessageInstance);
          return userMessageInstance.user;
        }); */
        return userMessageInstance.user;
      });
    },
  },
  actions: {
    scrollToLatestMessage() {
      const chatMsgContainerElementRef = document.querySelector(
        "#chat-messager-container .ps"
      );
      if (chatMsgContainerElementRef) {
        chatMsgContainerElementRef.scrollTop =
          chatMsgContainerElementRef.scrollHeight;
      }
    },
  },
};

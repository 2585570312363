export default (el, _binding, vnode) => {
  const store = vnode.context.$store;
  const allowedPermissions = store.getters["auth/permissions"];
  const inputPermissions = _binding.value || [];
  const foundPermission = allowedPermissions.find(
    (perm) => inputPermissions.indexOf(perm) != -1
  );

  if (!foundPermission) {
    // replace HTMLElement with comment node
    const comment = document.createComment(" ");
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined,
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }
};

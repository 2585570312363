import BaseModel from "./BaseModel";

export class FlightModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      booked_by: undefined,
      takeoff_time: undefined,
      status: undefined,
      type: undefined,
      trip: undefined,
      booking_date: undefined,
      booking_time: undefined,
      booking_details: undefined,
      passengers: undefined,
      pricing: undefined,
      comments: [],
    };
  }
}

<template>
  <div>
    <router-view></router-view>
    <notifications></notifications>
  </div>
</template>

<script>
export default {};
</script>

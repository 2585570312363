const initialState = () => {
  return {
    invitaitonList: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    invitationList(state) {
      return state.invitaitonList;
    },
  },
  mutations: {
    setList(state, payload) {
      state.invitaitonList = payload;
    },
    addNew(state, payload) {
      state.invitaitonList = [...state.invitaitonList, payload];
    },
    remove(state, recordID) {
      const idx = state.invitaitonList.findIndex((item) => item.id == recordID);
      if (idx != -1) {
        state.invitaitonList.splice(idx, 1);
      }
    },
  },
};

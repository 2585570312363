import Vue from "vue";
import Vuex from "vuex";

import authModule from "./auth";
import airportModule from "./airport";
import flightModule from "./flight";
import settingModule from "./setting";
import chatModule from "./chat";
import invitationModule from "./invitaiton";
import immidiatetakeoffModule from "./immidiatetakeoff";
import usersModule from "./user";
import membersModule from "./member";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    auth: authModule,
    airport: airportModule,
    flight: flightModule,
    setting: settingModule,
    chat: chatModule,
    invitation: invitationModule,
    member: membersModule,
    user: usersModule,
    immidiatetakeoff: immidiatetakeoffModule,
  },
});

const initialState = () => {
  return {
    membersList: [],
    usersList: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    membersList(state) {
      return state.membersList;
    },
    usersList(state) {
      return state.usersList;
    },
  },
  mutations: {
    setList(state, payload) {
      state.membersList = payload;
    },
    setUserList(state, payload) {
      state.usersList = payload;
    },
    addNew(state, payload) {
      state.membersList = [...state.membersList, payload];
    },
    addUser(state, payload) {
      state.usersList = [...state.usersList, payload];
    },
    removemember(state, recordID) {
      const idx = state.membersList.findIndex((item) => item.id == recordID);
      if (idx != -1) {
        state.membersList.splice(idx, 1);
      }
    },
    removeuser(state, recordID) {
      const idx = state.usersList.findIndex((item) => item.id == recordID);
      if (idx != -1) {
        state.usersList.splice(idx, 1);
      }
    },

    updateField(state, payload) {
      const recordID = payload["id"];
      const field = payload["field"] || {};

      const recordIndex = state.usersList.findIndex(
        (item) => item.id == recordID
      );
      if (recordIndex != -1) {
        const selectedRecord = state.usersList[recordIndex];
        const newState = {
          ...selectedRecord,
          ...field,
        };

        //Update State
        Object.assign(state.usersList[recordIndex], newState);
      }
    },

    updateFieldMember(state, payload) {
      const recordID = payload["id"];
      const field = payload["field"] || {};

      const recordIndex = state.membersList.findIndex(
        (item) => item.id == recordID
      );
      if (recordIndex != -1) {
        const selectedRecord = state.membersList[recordIndex];
        const newState = {
          ...selectedRecord,
          ...field,
        };

        //Update State
        Object.assign(state.membersList[recordIndex], newState);
      }
    },
    updateRewards(state, payload) {
      const foundIndex = state.membersList.findIndex(
        (item) => payload.id == item.id
      );
      state.membersList[foundIndex].rewards.push(payload.response);
    },
    updateInvitation(state, payload) {
      const foundIndex = state.membersList.findIndex(
        (item) => payload.id == item.id
      );
      state.membersList[foundIndex].invitations.push(payload.response);
    },
  },
};

<template>
  <div class="wrapper flygi-layout">
    <div class="flygi-layout__nav">
      <app-header></app-header>
    </div>

    <div class="flygi-layout__content">
      <!-- <side-bar>
        <template slot="links">
          <sidebar-item
            :link="{
              name: 'Dashboard',
              icon: 'ni ni-shop text-primary',
            }"
          >
            <sidebar-item
              :link="{ name: 'Home', path: '/dashboard' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'About',
              icon: 'ni ni-ungroup text-orange',
            }"
          >
            <sidebar-item
              :link="{ name: 'Story', path: '/about' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'Tables',
              icon: 'ni ni-align-left-2 text-default',
            }"
          >
            <sidebar-item :link="{ name: 'Flights', path: '/flights' }" />
          </sidebar-item>
        </template>
      </side-bar> -->
      <div class="main-content">
        <div @click="$sidebar.displaySidebar(false)">
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
        </div>
      </div>
    </div>
    <!-- <notifications></notifications> -->
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import AppHeader from "./Header.vue";
// import SideBar from "./sidebar/SideBar.vue";

import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    AppHeader,
    FadeTransition,
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
};
</script>
<style lang="scss"></style>

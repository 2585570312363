import moment from "moment";

export class DateTime {
  static moment = moment;

  static format(dateStr, format = "", fromFormat = "") {
    if (fromFormat) {
      return moment(dateStr || new Date(), fromFormat).format(format);
    } else {
      return moment(dateStr || new Date()).format(format);
    }
  }

  static parseDate(dateStr, _format = "") {
    if (_format) {
      return moment(dateStr || new Date(), _format);
    }
    return moment(dateStr || new Date());
  }

  static utc(dateStr, _format = "YYYY-MM-DD HH:mm") {
    return moment(dateStr, _format || "YYYY-MM-DD HH:mm")
      .utc()
      .format(_format || "YYYY-MM-DD HH:mm");
  }

  static parseUtc(dateStr, format, fromFormat = null) {
    return moment
      .utc(dateStr, fromFormat || format || "YYYY-MM-DD HH:mm")
      .local()
      .format(format || "YYYY-MM-DD HH:mm");
  }
}

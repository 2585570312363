import { BaseService } from "./base.service";

import { InvitationModel } from "@/core/models";
export class InvitationService extends BaseService {
  name = "API_AUTH";

  invitations(_payload) {
    return this.get("/invitation").then((res) => {
      const data = res.data.map((item) => {
        return new InvitationModel(item);
      });

      this.$store.commit("invitation/setList", data);
      return {
        ...res,
        data,
      };
    });
  }

  remove(invitationID) {
    return this.delete(`/invitation/${invitationID}`).then((res) => {
      return res;
    });
  }

  resend_invite(invitationID) {
    return this.get(`/invitation/${invitationID}/resend`).then((res) => {
      const data = res.data;

      // this.$store.commit("invitation/setList", data);

      return {
        ...res,
        data,
      };
    });
  }

  save(payload) {
    return this.post("/invitation", payload).then((res) => {
      const data = res.data;

      this.$store.commit("invitation/addNew", data);
      return res;
    });
  }

  saveInvitation(payload) {
    return this.post("/invitation", payload).then((res) => {
      return res;
    });
  }
  drop(invitationID) {
    this.$store.commit("invitation/remove", invitationID);
  }
}

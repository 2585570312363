<template>
  <div class="dynamic-component">
    <template v-if="componentType == 'badge'">
      <badge
        :type="column.variant[fieldData]"
        custom-class="badge-md badge-flight badge-flight-w"
      >
        {{ fieldData }}
      </badge>
    </template>
    <template v-if="componentType == 'date-time'">
      <div class="datetime-col">
        <span class="datetime-col__date">{{ dateFormatter }}</span>
        <span class="datetime-col__time">{{ timeFormatter }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime, Helper } from "@/core/utils";
export default {
  name: "DynamicComponent",
  props: {
    componentType: {
      type: String,
      default: "",
    },
    column: {
      type: Object,
      default: () => {
        return {
          prop: "",
          label: "",
          propPath: "",
        };
      },
    },
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeState: false,
    };
  },
  computed: {
    fieldData() {
      if (this.column.propPath) {
        return Helper.getPropByString(this.row, this.column.propPath);
      } else if (this.componentType == "badge") {
        if (this.column.formatter) {
          return this.column.formatter(
            this.row,
            this.column,
            this.row[this.column.prop]
          );
        } else {
          return (
            (this.row[this.column.prop] &&
              this.row[this.column.prop].replace("_", " ")) ||
            ""
          );
        }
      } else {
        return this.row[this.column.prop];
      }
    },
    dateFormatter() {
      if (this.componentType == "date-time") {
        if (this.column.dateLocal) {
          return DateTime.parseUtc(
            this.fieldData,
            "Y-MM-DD",
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          return DateTime.format(this.fieldData, "Y-MM-DD");
        }
      } else {
        return "";
      }
    },
    timeFormatter() {
      if (this.componentType == "date-time") {
        if (this.column.dateLocal) {
          return DateTime.parseUtc(
            this.fieldData,
            "HH:mm",
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          return DateTime.format(this.fieldData, "HH:mm");
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .datetime-col {
    &__date {
      margin-right: 1.15em;
    }
    &__time {
      white-space: nowrap;
    }
  }
}
</style>

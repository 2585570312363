export const APP_CONFIG = {
  apiUrl: parse(process.env.VUE_APP_API_URL, ""),
  appUrl: parse(process.env.VUE_APP_APP_URL, ""),
  debugMode: parse(process.env.VUE_APP_APP_DEBUG, false),
  prefix: "flygi_",
  firebase: {
    serverKey: parse(process.env.VUE_APP_FIREBASE_SERVER_KEY, ""),
    access: {
      apiKey: parse(process.env.VUE_APP_FIREBASE_API_KEY, ""),
      authDomain: parse(process.env.VUE_APP_FIREBASE_DOMAIN, ""),
      databaseURL: parse(process.env.VUE_APP_FIREBASE_DB_URL, ""),
      projectId: parse(process.env.VUE_APP_FIREBASE_PROJECT_ID, ""),
      storageBucket: parse(process.env.VUE_APP_FIREBASE_STORAGE_BUCKET, ""),
      messagingSenderId: parse(process.env.VUE_APP_FIREBASE_SENDER_ID, ""),
      appId: parse(process.env.VUE_APP_FIREBASE_APP_ID, ""),
    },
    chatUrl:
      parse(process.env.VUE_APP_FIREBASE_STORAGE_BUCKET, "") + "/support_chats",
    support: {
      username: "support@flygi.com",
      password: "12345678",
      displayName: "Flygi Support",
      firebaseUid: "bdwSdT1q47MHDLNqIJyEDAdLp542",
    },
  },
};

function parse(value, fallback) {
  if (typeof value === "undefined") {
    return fallback;
  }
  switch (typeof fallback) {
    case "boolean":
      return !!JSON.parse(value);
    case "number":
      return JSON.parse(value);
    default:
      return value;
  }
}

<template>
  <div>
    <base-input :label="label" :rules="rules" :name="name">
      <el-select
        v-model="model"
        :multiple="multiple"
        :clearable="clearable"
        :searchable="searchable"
        :placeholder="placeholder"
        :disabled="disabled"
        :size="size"
        @change="onChange($event)"
      >
        <el-option
          v-for="option in options"
          :key="
            optType != 'single'
              ? option.id
                ? option.id
                : option.label
              : option
          "
          :label="
            optType != 'single'
              ? optLabel
                ? option[optLabel]
                : option.label
              : option
          "
          :value="
            optType != 'single'
              ? optVal
                ? option[optVal]
                : option.value
              : option
          "
        ></el-option>
      </el-select>
    </base-input>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {
  name: "BaseSelectbox",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    optType: {
      type: [String],
      default: "",
    },
    optLabel: {
      type: [String, Number],
      default: "",
    },
    optVal: {
      type: [String, Number, Boolean],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Array, Boolean],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styleClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      description: "size (large/small/mini)",
      default: "",
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<style lang="scss">
.input-error-container {
  .el-select {
    .el-input__inner {
      border: 1px solid #fb6340 !important;
    }
  }
}
</style>

import {
  authService,
  localStorageService,
  firebaseService,
} from "@/core/services";
import { attachAuthInterceptor } from "@/core/guards/interceptor";

const initialState = () => {
  return {
    user: null,
    token: null,
    expiresIn: null,
    remoteProvider: null,
    permissions: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    user(state) {
      return state.user;
    },
    fullName(state) {
      return state.user
        ? state.user.first_name + " " + state.user.last_name
        : "";
    },
    token(state) {
      return state.token;
    },
    permissions(state) {
      return state.permissions;
    },
  },
  mutations: {
    setAuth(state, payload) {
      console.log("state, payload", state, payload);
      state.token = payload.access_token;
      state.user = payload.user;
      state.remoteProvider = payload.remoteProvider;
    },
    clearAuth(state) {
      Object.assign(state, initialState());

      firebaseService.logoutFcm();
      localStorageService.clear();
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
  },
  actions: {
    login(_context, payload) {
      let response;

      return authService
        .login(payload)
        .then((res) => {
          response = res;
          _context.commit("setAuth", res.data);
          authService.setJwtToken(res.data.access_token, {
            token: res.data.remoteProvider._delegate.accessToken,
            uid: res.data.remoteProvider._delegate.uid,
          });
          firebaseService.getFCMToken();
          attachAuthInterceptor();

          return authService.getPermissions();
        })
        .then((permissions) => {
          _context.commit("setPermissions", permissions);
          return response;
        });
    },
    logout(context) {
      context.commit("clearAuth");
    },
    authenticate(_context) {
      let response;

      return authService
        .authenticateUser()
        .then((res) => {
          response = res;
          _context.commit("setAuth", res);
          return authService.getPermissions();
        })
        .then((permissions) => {
          _context.commit("setPermissions", permissions);
          return response;
        });
    },
  },
};

<template>
  <base-input>
    <quill-editor
      v-model="model"
      style="height: 200px"
      :options="options"
    ></quill-editor>
  </base-input>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

export default {
  name: "BaseEditor",
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    console.log("Component mounted.");
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <component
    :is="tag"
    :class="{ active: isActive }"
    @mouseover="onOver"
    @mouseleave="onLeave"
  >
    <b-dropdown
      ref="dropdown"
      :text="link.name"
      :right="right"
      :no-caret="noCaret"
    >
      <template #button-content>
        <router-link
          tag="a"
          :to="link.path"
          exact
          exact-active-class="nav-active-link"
          @click.native="onMenuItemClick"
        >
          <i v-if="iconLink" :class="iconLink"></i>
          <span class="nav-link-text">{{ link.name }}</span>
        </router-link>
      </template>
      <slot></slot>
    </b-dropdown>
  </component>
</template>
<script>
export default {
  name: "BaseNavDropdown",
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        };
      },
      description:
        "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
    },
    tag: {
      type: String,
      default: "div",
      description: "Dropdown html tag (e.g div, ul etc)",
    },
    iconLink: {
      type: String,
      default: "",
    },
    right: {
      type: Boolean,
      default: true,
      description: "Dropdown html tag (e.g div, ul etc)",
    },
    titleTag: {
      type: String,
      default: "button",
      description: "Dropdown title (toggle) html tag",
    },
    title: {
      type: String,
      description: "Dropdown title",
    },
    direction: {
      type: String,
      default: "down", // up | down
      description: "Dropdown menu direction (up|down)",
    },
    icon: {
      type: String,
      description: "Dropdown icon",
    },
    titleClasses: {
      type: [String, Object, Array],
      description: "Title css classes",
    },
    menuClasses: {
      type: [String, Object],
      description: "Menu css classes",
    },
    menuOnRight: {
      type: Boolean,
      description: "Whether menu should appear on the right",
    },
    noCaret: {
      type: Boolean,
      default: false,
      description: "Whether menu should appear on the right",
    },
    hasToggle: {
      type: Boolean,
      description: "Whether dropdown has arrow icon shown",
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      children: [],
    };
  },
  computed: {
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
    onMenuItemClick() {
      if (this.link.path && this.$route.path !== this.link.path) {
        this.$router.push(this.link.path);
      }
      this.$refs.dropdown.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  cursor: pointer;
  user-select: none;
}
::v-deep {
  .dropdown {
    .btn {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0.375rem;
      background-color: transparent;
      border: 0;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      &:hover {
        background-color: transparent;
        border: 0;
      }
      a {
        color: inherit;
      }
    }
  }
}
</style>

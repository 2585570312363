import BaseModel from "./BaseModel";

export class UserModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      access_token: undefined,
    };
  }
}

import { Helper } from "../utils";

export class FormGroup {
  fields = [];
  initializeState = {};

  constructor(obj = {}) {
    this.fields = [];
    Object.keys(obj).forEach((fieldName) => {
      if (!this[fieldName]) {
        this[fieldName] = {
          value: obj[fieldName][0],
          rule: obj[fieldName][1],
        };

        if (Helper.isObject(obj[fieldName][0])) {
          this.initializeState[fieldName] = {};
        } else if (Helper.isArray(obj[fieldName][0])) {
          this.initializeState[fieldName] = [];
        } else {
          this.initializeState[fieldName] = null;
        }
      }
      this.fields.push(fieldName);
    });
  }

  get value() {
    const group = {};
    this.fields.forEach((fieldName) => {
      group[fieldName] = this[fieldName].value;
    });
    return group;
  }

  reset() {
    this.fields.forEach((_fieldName) => {
      this[_fieldName].value = this.initializeState[_fieldName];
    });
  }

  populate(data = {}) {
    Object.keys(data).forEach((_fieldName) => {
      if (Object.prototype.hasOwnProperty.call(this, _fieldName)) {
        this[_fieldName].value = data[_fieldName];
      }
    });
  }
}

import HttpClient from "./http-client";
import {
  NetworkError,
  NetworkResponse,
} from "@/core/abstracts/network-response";
import { APP_CONFIG } from "@/config";
import { notificationService } from "..";

export class BaseService {
  http = HttpClient;
  httpMetaParams = {};

  async post(url, data, headers = null, opt = null) {
    this.appendHeader(headers, opt);

    return this.http
      .post(`${url}`, data, this.httpMetaParams)
      .then((res) => {
        this.showConsole(res);
        return new NetworkResponse(res);
      })
      .catch((err) => {
        const networkErr = new NetworkError(err);
        console.log(err, "err");
        notificationService.error(networkErr.message);
        //this.showErrorConsole(networkErr);
        if (networkErr.status === 401) {
          console.log(networkErr.status);
          window.location.href = "/login";
        }
        throw networkErr;
      });
  }

  get(url, _params) {
    return this.http
      .get(`${url}`)
      .then((res) => {
        this.showConsole(res);
        return new NetworkResponse(res);
      })
      .catch((err) => {
        const networkErr = new NetworkError(err);
        console.log(networkErr, "err");

        notificationService.error(networkErr.message);
        if (networkErr.status === 401) {
          console.log(networkErr.status);
          window.location.href = "/login";
        }
        throw networkErr;
      });
  }

  put(url, data) {
    return this.http
      .put(`${url}`, data)
      .then((res) => {
        this.showConsole(res);
        return new NetworkResponse(res);
      })
      .catch((err) => {
        const networkErr = new NetworkError(err);

        notificationService.error(networkErr.message);
        if (networkErr.status === 401) {
          console.log(networkErr.status);
          window.location.href = "/login";
        }
        throw networkErr;
      });
  }

  patch(url, data) {
    return this.http
      .patch(`${url}`, data)
      .then((res) => {
        this.showConsole(res);
        return new NetworkResponse(res);
      })
      .catch((err) => {
        const networkErr = new NetworkError(err);

        notificationService.error(networkErr.message);
        if (networkErr.status === 401) {
          console.log(networkErr.status);
          window.location.href = "/login";
        }
        throw networkErr;
      });
  }

  async delete(url) {
    return this.http
      .delete(`${url}`)
      .then((res) => {
        this.showConsole(res);
        return new NetworkResponse(res);
      })
      .catch((err) => {
        const networkErr = new NetworkError(err);

        notificationService.error(networkErr.message);
        if (networkErr.status === 401) {
          console.log(networkErr.status);
          window.location.href = "/login";
        }
        throw networkErr;
      });
  }

  showConsole(response) {
    if (APP_CONFIG.debugMode) console.log(response);
  }

  showErrorConsole(response) {
    if (APP_CONFIG.debugMode) console.dir(response);
  }

  appendHeader(headers, opt = null) {
    this.httpMetaParams = {};
    if (headers) {
      this.httpMetaParams.headers = headers;
    }
    if (opt) {
      Object.keys(opt).forEach((key) => {
        this.httpMetaParams[key] = opt[key];
      });
    }
  }
}

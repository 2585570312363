const initialState = () => {
  return {
    flightList: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    flightList(state) {
      return state.flightList;
    },
  },
  mutations: {
    setList(state, payload) {
      state.flightList = payload;
    },
    addNew(state, payload) {
      state.flightList.unshift(payload);
    },
    updateBookingStatus(state, payload) {
      const foundIndex = state.flightList.findIndex(
        (item) => payload.booking_id == item.id
      );
      state.flightList[foundIndex].status = payload.status;
    },
    updateField(state, payload) {
      const recordID = payload["id"];
      const field = payload["field"] || {};

      const recordIndex = state.flightList.findIndex(
        (item) => item.id == recordID
      );
      if (recordIndex != -1) {
        const selectedRecord = state.flightList[recordIndex];
        const isLiveselectedRecord = state.flightList[recordIndex].is_live;

        const newState = {
          ...selectedRecord,
          ...field,
        };
        let is_live = field.booking_details.find((r1) => r1.is_live == true);

        const isLiveData = {
          ...isLiveselectedRecord,
          ...is_live,
        };

        //Update State
        Object.assign(state.flightList[recordIndex], newState);
        Object.assign(state.flightList[recordIndex].is_live, isLiveData);
      }
    },
  },
};

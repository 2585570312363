<template>
  <div class="row">
    <div class="col-12">
      <div
        class="dt-header-control row flex-row-reverse justify-content-between"
      >
        <div class="col-md-3 col-sm-9 order-1">
          <base-input
            v-model="searchQuery"
            style="align: right"
            prepend-icon="fas fa-search"
            placeholder="Search..."
            input-classes="dt-search-input"
          ></base-input>
        </div>
        <div class="col-md-4 col-sm-3 order-2">
          <slot name="header-action"></slot>
        </div>
        <!-- <slot name="actions"></slot> -->
      </div>
    </div>
    <div class="col-12">
      <el-table
        ref="table"
        stripe
        :border="border"
        :lazy="true"
        :data="loading ? [] : queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        :row-class-name="rowClassName"
        @sort-change="changeOrder"
        @selection-change="selectionChange"
      >
        <template>
          <el-table-column
            v-for="column in tableColumns"
            :key="column.id"
            v-bind="column"
            :width="column.width || null"
            :align="column.align || null"
          >
            <template slot-scope="scope">
              <template v-if="column.component">
                <div @click="toggleExpand(scope.row)">
                  <dynamic-component
                    :component-type="column.component"
                    :column="column"
                    :row="scope.row"
                  ></dynamic-component>
                </div>
              </template>
              <template v-else>
                <span type="text" @click="toggleExpand(scope.row)">
                  {{
                    column.formatter
                      ? column.formatter(
                          scope.row,
                          column,
                          scope.row[column.prop]
                        )
                      : scope.row[column.prop]
                  }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            v-if="rowActions.length"
            min-width="70px"
            align="center"
            label="Actions"
          >
            <div slot-scope="scope" class="d-flex justify-content-center">
              <template v-for="actionColumn in actionColumns">
                <base-button
                  v-if="
                    actionColumn.condition
                      ? actionColumn.condition(scope.row)
                      : true
                  "
                  :key="actionColumn.key"
                  class="dt-action-btn"
                  :type="actionColumn.variant"
                  size="sm"
                  icon
                  @click.native="
                    rowBtnActionHandler(
                      actionColumn.handler,
                      scope.row,
                      scope.$index,
                      actionColumn
                    )
                  "
                >
                  <i class="text-white" :class="actionColumn.icon"></i>
                </base-button>
              </template>
            </div>
          </el-table-column>
          <el-table-column v-if="expanded" type="expand" width="1">
            <template slot-scope="props" style="border: 1px solid gray">
              <slot name="expandable" :item="props.row"></slot>
            </template>
          </el-table-column>
        </template>
        <template slot="empty">
          <div v-if="loading">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                dt-spinner
              "
            >
              <b-spinner variant="success"></b-spinner>
              <strong class="loading-text">Loading...</strong>
            </div>
          </div>
          <div v-else>No Records found</div>
        </template>
      </el-table>
    </div>
    <div class="col-12 my-4 px-0">
      <div
        slot="footer"
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <div class="paginator-option-control">
          <base-selectbox
            v-model="pagination.perPage"
            :options="pagination.perPageOptions"
            size="mini"
            :clearable="false"
            opt-type="single"
          ></base-selectbox>
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import DynamicComponent from "./DynamicComponent.vue";
import PaginationMixin from "./mixins/pagination.mixin";
import { Helper } from "@/core/utils";

export default {
  name: "DataTable",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TableColumn.name]: TableColumn,
    DynamicComponent,
  },
  mixins: [PaginationMixin],
  props: {
    tableData: Array,
    tableColumns: Array,
    rowActions: {
      type: Array,
      default: () => [],
    },
    rowClassName: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      changeOrder: {},
      selectionChange: {},
      search: "",
      actionColumns: [],
    };
  },
  computed: {
    datasource() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.customer.toLowerCase().includes(this.search.toLowerCase()) ||
          data.pilot.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    rowActions: {
      immediate: true,
      handler(newVal) {
        this.actionColumns = newVal.map((rowAction) => {
          return {
            ...rowAction,
            key: Helper.uid(),
          };
        });
        /* .filter((rowAction,index)=>{
          if(rowAction.condition){
            return rowAction.condition(this.datasource[index])
          }else{
            return true;
          }
        }) */
      },
    },
  },
  methods: {
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    toggleExpand(row) {
      let $table = this.$refs.table;
      $table.toggleRowExpansion(row);
    },
    rowBtnActionHandler(handler, _row, _index, _col) {
      if (handler) {
        handler(_row, _index, _col);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .is-center {
    .cell {
      justify-content: center;
    }
  }
  .row-expanded {
    .el-table__cell {
      .cell {
        word-break: break-word;
        span {
          cursor: pointer;
        }
      }
    }
  }
  .dt-spinner {
    padding: 12px 0;
    .loading-text {
      line-height: 35px;
      padding-left: 12px;
    }
  }
  .paginator-option-control {
    .form-group {
      margin-bottom: 0.7rem;
      .el-select {
        width: 60%;
        .el-input__inner {
          padding-right: 8px;
          height: calc(1.3em + 1rem + 2px);
        }
      }
    }
    .card-category {
      font-size: 0.88rem;
    }
  }
  .dt-header-control {
    .input-group-prepend {
      .input-group-text {
        padding: 0.45rem 0.75rem;
      }
    }
    .dt-search-input {
      height: calc(1.15em + 1rem + 5px);
    }
  }
  .dt-action-btn {
    padding: 0.3rem 0.5rem;
  }
}
</style>

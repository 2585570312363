import Vue from "vue";
import VueRouter from "vue-router";
import { ModalPlugin } from "bootstrap-vue";

import NotificationsPlugin from "@components/toastr";

class Provider extends Vue {}

export class ServiceProvider {
  provider = makeProvider();
}

export default function makeProvider() {
  Provider.use(ModalPlugin);
  Provider.use(VueRouter);
  Provider.use(NotificationsPlugin);

  return new Provider();
}

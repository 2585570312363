<template>
  <b-overlay
    :show="show"
    :no-wrap="noWrap"
    variant="white"
    opacity="0.8"
    blur="2px"
    spinner-variant="success"
  >
    <slot></slot>
  </b-overlay>
</template>

<script>
export default {
  name: "ProgressLoader",
  props: {
    show: {
      type: Boolean,
      default: false,
      description: "Whether to show loading",
    },
    noWrap: {
      type: Boolean,
      default: false,
      description: "Whether to show loading",
    },
  },
  data() {
    return {};
  },
};
</script>

<template>
  <section>
    <div class="px-4 d-none d-md-block">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <input
            v-model="searchText"
            type="text"
            class="form-control my-3 search-input"
            placeholder="Search..."
            @keyup="onSearchUserContact"
          />
        </div>
      </div>
    </div>

    <perfect-scrollbar>
      <div class="user--list">
        <span
          v-for="user in contactList"
          :key="user.id"
          class="
            list-group-item list-group-item-action
            border-0
            user--list_item
          "
          :class="{ 'user--list_item--active': isActiveUser == user.id }"
          @click="selectUser(user)"
        >
          <div
            v-if="userMessageUnReadCount(user) != 0"
            class="badge bg-success float-right unread-count"
          >
            {{ userMessageUnReadCount(user) }}
          </div>
          <div class="d-flex align-items-center">
            <img
              src="@assets/img/chat-user.png"
              class="rounded-circle mr-1 user--img"
              alt="Vanessa Tucker"
              width="40"
              height="40"
            />
            <div class="flex-grow-1 item-title">
              {{ user.full_name }}
              <!-- <div class="small">
            <span class="fas fa-circle chat-online"></span>
            Online
          </div> -->
            </div>
          </div>
        </span>
        <div v-if="contactList.length == 0" class="no-user-list">
          <span>No Users Found</span>
        </div>
      </div>
    </perfect-scrollbar>

    <hr class="d-block d-lg-none mt-1 mb-0" />
  </section>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapGetters } from "vuex";

import { firebaseService } from "@/core/services";

export default {
  name: "ContactList",
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      searchText: "",
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters("chat", ["userList", "pickedUser"]),
    contactList() {
      return this.searchText ? this.filteredUsers : this.userList;
    },
    isActiveUser() {
      return (this.pickedUser && this.pickedUser.id) || null;
    },
    userMessageUnReadCount() {
      return (user) => {
        let found;
        if (user) {
          found = this.$store.getters["chat/userMessageList"].find((item) => {
            return item.chatUserUid == user.firebase_id;
          });
        }
        return found ? found.unreadCount : 0;
      };
    },
  },
  mounted() {},
  methods: {
    selectUser(user) {
      this.$store.commit("chat/chosenUser", user);
      firebaseService.markReadSelectedUserMessages(user.firebase_id);
    },
    onSearchUserContact() {
      this.filteredUsers = this.userList.filter((item) => {
        return new RegExp(this.searchText, "gi").test(item.full_name);
      });
    },
  },
};
</script>

<style lang="scss"></style>

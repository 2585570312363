import { ServiceProvider } from "./provider";

export class NotificationService extends ServiceProvider {
  option = {
    timeout: 5000,
    icon: "ni ni-bell-55",
    verticalAlign: "bottom",
    horizontalAlign: "right",
  };

  #setType(type = "default") {
    this.option = {
      ...this.option,
      type,
    };
  }

  success(message) {
    this.#setType("success");
    this.provider.$notify({
      ...this.option,
      message,
    });
  }

  error(message) {
    this.#setType("danger");
    this.provider.$notify({
      ...this.option,
      message,
    });
  }

  warning(message) {
    this.#setType("warning");
    this.provider.$notify({
      ...this.option,
      message,
    });
  }

  info(message) {
    this.#setType("info");
    this.provider.$notify({
      ...this.option,
      message,
    });
  }
}

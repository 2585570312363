import { AirportService } from "./api/airport.service";
import { AuthService } from "./api/auth.service";
import { ConfirmDialogService } from "./confirm-dialog.service";
import { LocalStorageService } from "./local-storage.service";
import { NotificationService } from "./notification.service";
import { FlightService } from "./api/flight.service";
import { InvitationService } from "./api/invitation.service";
import { ImmidiatetakeoffService } from "./api/immidiatetakeoff.service";
import { UsersService } from "./api/users.service";
import { MembersService } from "./api/member.service";
import { SettingService } from "./api/setting.service";
import { EventService } from "./event.service";
import { FirebaseService } from "./api/firebase.service";
import { AlertService } from "./alert.service";

import "./service-worker";

export const localStorageService = new LocalStorageService();
export const confirmDialogService = new ConfirmDialogService();
export const notificationService = new NotificationService();
export const eventService = new EventService();
export const authService = new AuthService();
export const flightService = new FlightService();
export const immidiatetakeoffService = new ImmidiatetakeoffService();
export const invitationService = new InvitationService();
export const usersService = new UsersService();
export const membersService = new MembersService();
export const airportService = new AirportService();
export const settingService = new SettingService();
export const firebaseService = new FirebaseService();
export const alertService = new AlertService();

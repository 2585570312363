const initialState = () => {
  return {
    immediateBookings: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    immediateBookings(state) {
      return state.immediateBookings;
    },
  },
  mutations: {
    setList(state, payload) {
      state.immediateBookings = payload;
    },
    addNew(state, payload) {
      state.immediateBookings = [...state.immediateBookings, payload];
    },
    removeImmediateBooking(state, payload) {
      const foundIndex = state.immediateBookings.findIndex(
        (item) => payload.booking_id == item.id
      );
      if (foundIndex != -1) {
        state.immediateBookings.splice(foundIndex, 1);
      }
    },
  },
};

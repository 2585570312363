import { BaseService } from "./base.service";

export class UsersService extends BaseService {
  name = "API_AUTH";

  users(_payload) {
    return this.get("/invitation").then((res) => {
      // const data = res.data.map((item) => {
      //   return new UsersModel(item);
      // });
      let data = [
        {
          id: 1,
          full_name: "Hassan",
          username: "hassan21",
          role: "developer",
        },
        {
          id: 2,
          full_name: "talha",
          username: "talha21",
          role: "developer",
        },
        {
          id: 3,
          full_name: "faraz",
          username: "faraz21",
          role: "developer",
        },
        {
          id: 4,
          full_name: "shahzaib",
          username: "shahzaib21",
          role: "developer",
        },
      ];

      this.$store.commit("user/setList", data);
      return {
        ...res,
        data,
      };
    });
  }

  firebaseusers() {
    return this.get("/users/firebaseUsers").then((res) => {
      // const data = res.data.map((item) => {
      //   return new UsersModel(item);
      // });

      return {
        ...res,
        res,
      };
    });
  }

  remove(invitationID) {
    return this.delete(`/invitation/${invitationID}`).then((res) => {
      return res;
    });
  }

  resend_invite(invitationID) {
    return this.get(`/invitation/${invitationID}/resend`).then((res) => {
      const data = res.data;

      // this.$store.commit("invitation/setList", data);

      return {
        ...res,
        data,
      };
    });
  }

  save(payload) {
    return this.post("/invitation", payload).then((res) => {
      const data = res.data;

      this.$store.commit("invitation/addNew", data);
      return res;
    });
  }

  drop(invitationID) {
    this.$store.commit("invitation/remove", invitationID);
  }
}

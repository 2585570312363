<template>
  <b-popover ref="popover" :show.sync="active" :target="target">
    <template #title><slot name="title"></slot></template>
    <slot></slot>
  </b-popover>
</template>

<script>
export default {
  name: "BaseTooltip",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeState: false,
    };
  },
  computed: {
    active: {
      get() {
        return this.activeState;
      },
      set(value) {
        this.activeState = value;
        if (value === false) {
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    this.active = this.show;
  },
  methods: {
    onOpen() {
      this.$refs.popover.$emit("open");
    },
    onClose() {
      this.$refs.popover.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>

import { Helper } from "@/core/utils";

export default {
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let combinedPattern = this.timmedText;
      const pattern = new RegExp(combinedPattern, "gi");

      if (!this.timmedText) {
        this.searchedData = [];
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.searchableColumns) {
          let rowValue;
          const columnHeader = this.tableColumns.find((col) => col.prop == key);

          if (columnHeader.formatter) {
            rowValue = columnHeader.formatter(row);
          } else {
            rowValue = row[key].toString();
          }

          /* if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          } */
          if (pattern.test(rowValue)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.searchedData = [...result];
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return Helper.trim(this.searchQuery)
        ? this.searchedData.length
        : this.tableData.length;
    },
    searchableColumns() {
      return this.tableColumns
        .filter((item) => item.searchable)
        .map((item) => item.prop);
    },
    timmedText() {
      return Helper.trim(this.searchQuery);
    },
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: "",
    };
  },
  watch: {
    searchQuery(_newVal, _oldVal) {
      this.pagination.currentPage = 1;
    },
  },
  methods: {
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
  },
};

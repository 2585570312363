<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">About</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card
            title="Total traffic"
            type="gradient-red"
            sub-title="350,897"
            icon="ni ni-active-40"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                3.48%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col md="6" xl="3">
          <stats-card
            title="Total traffic"
            type="gradient-orange"
            sub-title="2,356"
            icon="ni ni-chart-pie-35"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                12.18%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col md="6" xl="3">
          <stats-card
            title="Sales"
            type="gradient-green"
            sub-title="924"
            icon="ni ni-money-coins"
          >
            <template slot="footer">
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i>
                5.72%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col md="6" xl="3">
          <stats-card
            title="Performance"
            type="gradient-info"
            sub-title="49,65%"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                54.8%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body class="border-0">
            <div
              id="map"
              class="map-canvas"
              style="
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <h2>Our Mission</h2>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!--Notice modal-->
    <modal :show.sync="modals.notice">
      <h6 slot="header" class="modal-title">Your attention is required</h6>

      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">You should read this!</h4>
        <p>
          A small river named Duden flows by their place and supplies it with
          the necessary regelialia.
        </p>
      </div>

      <template slot="footer">
        <base-button type="white">Ok, Got it</base-button>
        <base-button
          type="link"
          class="text-white ml-auto"
          @click="modals.notice = false"
        >
          Close
        </base-button>
      </template>
    </modal>
    <base-button type="primary" @click="open()">Ok, Got it</base-button>
  </div>
</template>

<script>
import { RouteBreadCrumb, StatsCard, Modal } from "@/components";
import { confirmDialogService } from "@/core/services";

export default {
  name: "Dashboard",
  components: {
    StatsCard,
    RouteBreadCrumb,
    Modal,
  },
  data() {
    return {
      modals: {
        notice: false,
      },
    };
  },
  methods: {
    open() {
      //this.modals.notice = true;
      confirmDialogService.open(
        "Are you sure you want to cancel thi flight?",
        () => {
          console.log("CALL API");
        }
      );
    },
  },
};
</script>

<style></style>

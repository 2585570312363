var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-input',{attrs:{"label":_vm.label,"rules":_vm.rules,"name":_vm.name}},[_c('el-select',{attrs:{"multiple":_vm.multiple,"clearable":_vm.clearable,"searchable":_vm.searchable,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"size":_vm.size},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.options),function(option){return _c('el-option',{key:_vm.optType != 'single'
            ? option.id
              ? option.id
              : option.label
            : option,attrs:{"label":_vm.optType != 'single'
            ? _vm.optLabel
              ? option[_vm.optLabel]
              : option.label
            : option,"value":_vm.optType != 'single'
            ? _vm.optVal
              ? option[_vm.optVal]
              : option.value
            : option}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default class BaseModel {
  constructor(src = {}) {
    this.buildModel(src, this.schema);
  }

  get schema() {
    throw new Error("Model schema not defined.");
  }

  buildModel(src = {}, schema = {}) {
    Object.keys(schema).forEach((schemaProp) => {
      const srcPropType = typeof src[schemaProp];
      if (
        ["number", "boolean", "string", "object"].includes(srcPropType) &&
        ![undefined, null].includes(src[schemaProp])
      ) {
        this[schemaProp] = src[schemaProp];
      } else {
        this[schemaProp] = schema[schemaProp];
      }
    });
  }

  update(src = {}) {
    Object.keys(this.schema).forEach((schemaProp) => {
      this[schemaProp] = src[schemaProp];
    });
  }
}

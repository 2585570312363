import BaseModel from "./BaseModel";

export class AirportModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      name: undefined,
      code: undefined,
      image_url: undefined,
      country: undefined,
      city: undefined,
      lat: undefined,
      long: undefined,
      map_location: undefined,
      airport_fees: undefined,
      is_active: undefined,
      is_day_only: undefined,
      description: undefined,
      status: undefined,
      from_airport_details: [],
      to_airport_details: [],
      comments: [],
    };
  }
}

import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "@views/home/Dashboard.vue";
import SupportChat from "@views/support/SupportChat.vue";
import About from "@views/About.vue";
import Forbidden from "@views/error/Forbidden.vue";
// import Flights from "@views/flights/Flights.vue";
// import Airports from "@views/airports/Airports.vue";
import Login from "@views/auth/Login";
import Register from "@views/auth/Register";
import AppLayout from "@/layouts/AppLayout";
import AuthLayout from "@/layouts/AuthLayout";

import routeGuard from "./route-guard";

import authGuard from "@/core/guards/auth.guard";
import hasPermissionGuard from "@/core/guards/has-permission.guard";

import { PERMISSION } from "@/core/enums/permission";

Vue.use(VueRouter);

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    /* 
    { path: "*", component: NotFound }, */
  ],
};

const routes = [
  authPages,
  {
    path: "/",
    component: AppLayout,
    name: "Home",
    children: [
      { name: "Dashboard", component: Dashboard, path: "dashboard" },
      { name: "Story", component: About, path: "about" },
      {
        path: "/flights",
        name: "Flights",
        component: () =>
          import(
            /* webpackChunkName: "flight" */ "../views/flights/Flights.vue"
          ),
        meta: {
          permissions: [PERMISSION.FLIGHT],
        },
      },
      {
        path: "/users",
        name: "Users",
        component: () =>
          import(/* webpackChunkName: "Users" */ "../views/users/Users.vue"),
        meta: {
          permissions: [PERMISSION.USER],
        },
      },
      {
        path: "/members",
        name: "Members",
        component: () =>
          import(
            /* webpackChunkName: "Members" */ "../views/members/Members.vue"
          ),
        meta: {
          permissions: [PERMISSION.MEMBER],
        },
      },
      {
        path: "/flight/closedflights",
        name: "ClosedFlights",
        component: () =>
          import(
            /* webpackChunkName: "closedflight" */ "../views/immidiatetakeoff/Immidiatetakeoff.vue"
          ),
        meta: { status: "completed" },
      },
      {
        path: "/flight/immidiatetakeoff",
        name: "Immediate Take Off",
        component: () =>
          import(
            /* webpackChunkName: "immidiatetakeoff" */ "../views/immidiatetakeoff/Immidiatetakeoff.vue"
          ),
        meta: { status: "pending", type: "&type[0]=immediate" },
      },
      {
        path: "/flight/modifiedflights",
        name: "ModifiedFlights",
        component: () =>
          import(
            /* webpackChunkName: "immidiatetakeoff" */ "../views/immidiatetakeoff/Immidiatetakeoff.vue"
          ),
        meta: { status: "modified" },
      },
      {
        path: "/invitation",
        name: "Invitation",
        component: () =>
          import(
            /* webpackChunkName: "invite" */ "../views/invitation/Invitation.vue"
          ),
        meta: {
          permissions: [PERMISSION.INVITE],
        },
      },
      {
        path: "/airports",
        name: "Airports",
        component: () =>
          import(
            /* webpackChunkName: "airport" */ "../views/airports/Airports.vue"
          ),
        meta: {
          permissions: [PERMISSION.AIRPORT],
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/Settings.vue"
          ),
        meta: {
          permissions: [PERMISSION.SETTING],
        },
      },
      {
        name: "SupportChat",
        component: SupportChat,
        path: "support-chat",
        meta: {
          permissions: [PERMISSION.CHAT],
        },
      },
      {
        path: "/forbidden",
        name: "Forbidden",
        component: Forbidden,
      },
    ],
    beforeEnter: routeGuard([authGuard, hasPermissionGuard]),
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;

import { BaseService } from "./base.service";

import { FlightModel } from "@/core/models";
export class ImmidiatetakeoffService extends BaseService {
  name = "API_AUTH";

  bookings(id, status, type) {
    let url;

    if (id) {
      url = `/bookings/${id}?status[0]=${status} ${type}`;
    } else {
      url = `/bookings?status[0]=${status}${type}`;
    }

    return this.get(url).then((res) => {
      const data = res.data.map((item) => {
        let value = new FlightModel(item);
        if (value) {
          item = { ...item };

          if (item.booking_details.length) {
            let is_live = item.booking_details.find((r1) => r1.is_live == true);
            if (is_live) {
              item.is_live = is_live;
            }

            return item;
          }
        }
      });
      this.$store.commit("immidiatetakeoff/setList", data);
      return {
        ...res,
        data,
      };
    });
  }
  save_ferry(payload) {
    return this.post("/saveFlightLeg", payload).then((res) => {
      const data = res.data;
      this.$store.commit("immidiatetakeoff/addNew", data);
      return res;
    });
  }

  saveNote(airportID, payload) {
    return this.post(`/bookings/saveComment/${airportID}`, payload);
  }
}

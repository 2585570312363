import httpClient from "@core/services/api/http-client";
import { JWTService } from "@core/services/api/jwt.service";

export const attachAuthInterceptor = () => {
  if (JWTService.getToken()) {
    httpClient.interceptors.request.use((request) => {
      request.headers.Authorization = JWTService.bearerToken;
      return request;
    });
  }
};

import { ServiceProvider } from "./provider";
import swal from "sweetalert2";

export class AlertService extends ServiceProvider {
  option = {
    title: "",
    body: "",
    confirmBtnText: "",
    cancelBtnText: "",
    icon: "",
  };

  fbMsgQueue = [];

  immediateConfirmation(opt = {}) {
    const swalMixin = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-dark-success btn-md-text",
        cancelButton: "btn btn-danger btn-md-text",
        denyButton: "fa fa-times btn-times-icon",
        container: "fb-notify",
        icon: "flight-icon-container",
      },
      buttonsStyling: false,
    });

    return swalMixin.fire({
      title: `${opt.title}`,
      text: `${opt.body}`,
      iconHtml: '<span class="fa fa-plane flight-icon"></span>',
      //   confirmButtonClass: "btn btn-default",
      icon: "question",
      // showCancelButton: true,
      confirmButtonText: opt.confirmBtnText,
      // cancelButtonText: opt.cancelBtnText,
      showDenyButton: opt.showDenyButton ? true : false,
      denyButtonText: "",
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  close() {
    swal.close();
  }

  addMsgQueue() {
    this.fbMsgQueue.push(this.fbMsgQueue.length);
  }

  removeMsgQueue() {
    this.fbMsgQueue.shift();
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import DashboardPlugin from "./plugins/dashboard-plugin";
import { BaseService } from "./core/services/api/base.service";

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);

(function () {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
  BaseService.prototype.$store = store;
})();

import { BaseService } from "./base.service";

import { UserModel } from "@/core/models";
import { JWTService } from "./jwt.service";
import { firebaseService } from "@/core/services";
export class AuthService extends BaseService {
  name = "API_AUTH";

  login(payload) {
    let userResponse;

    return this.post("/login", payload)
      .then((res) => {
        userResponse = {
          ...res,
          data: {
            access_token: res.data.access_token,
            user: new UserModel(res.data),
          },
        };

        return firebaseService.login();
      })
      .then((firebaseUserData) => {
        userResponse.data.remoteProvider = firebaseUserData;
        return userResponse;
      });
  }

  authenticateUser() {
    let userState;
    return new Promise((resolve, reject) => {
      this.get("/users/getLoginUser")
        .then((res) => {
          userState = {
            access_token: this.getJwtToken(),
            user: new UserModel(res.data),
          };
          return userState;
        })
        .then((_) => {
          firebaseService.getFCMToken();
          return firebaseService.authenticate();
        })
        .then((res) => {
          userState.remoteProvider = res;
          resolve(userState);
        })
        .catch((e) => {
          if (userState) {
            if (firebaseService.firebaseAuth) {
              userState.remoteProvider = {
                _delegate: firebaseService.firebaseAuth._delegate,
              };
            }
            resolve(userState);
          } else {
            reject(e);
          }
        });
    });
  }

  logout() {
    return this.get("/logout").then(() => {
      console.log("test");
      firebaseService.logoutFcm();
      return true;
    });
  }

  getJwtToken() {
    const sess = JWTService.getToken();
    return (sess && sess.token) || null;
  }

  setJwtToken(accessToken, remoteDetails) {
    JWTService.setToken({
      token: accessToken,
      external_token: remoteDetails.token,
      external_uid: remoteDetails.uid,
    });
  }

  getPermissions() {
    return this.get(`/permissions`).then((res) => {
      const data = res.data;
      return data;
    });
  }

  hasPermission(permission = "delete-invite") {
    const permissions = this.$store.getters["auth/permissions"];

    return permissions.indexOf(permission) != -1;
  }
}

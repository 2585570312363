export const SETTING = {
  PRICING: "pricing_formula",
  BOOKING: "booking_margins",
  INVITATION: "invitations",
  TOKEN: "token_generation",
  TERMSANDCONDITION: "text",
  POLICY: "text",
  TEXT: "text",
  INFORMATIONPOPUP: "information_popup",
  OTHER: "other",
};

export const SETTING_EVENT = {
  PRICING: {
    SAVE: "save_pricing",
    CANCEL: "reset_pricing",
  },
  BOOKING: {
    SAVE: "save_booking_margin",
    CANCEL: "reset_booking_margin",
  },
  INVITATION: {
    SAVE: "save_invitation",
    CANCEL: "reset_invitation",
  },
  TOKEN: {
    SAVE: "save_token_generation",
    CANCEL: "reset_token_generation",
  },
  INFORMATIONPOPUP: {
    SAVE: "save_information_popup",
    CANCEL: "reset_information_popup",
  },
};

import { ServiceProvider } from "./provider";

export class ConfirmDialogService extends ServiceProvider {
  option = {
    title: "Please Confirm",
    size: "sm",
    buttonSize: "sm",
    okVariant: "danger",
    cancelVariant: "cancel",
    okTitle: "YES",
    cancelTitle: "NO",
    footerClass: "p-2",
    hideHeaderClose: false,
    centered: true,
    dialogClass: "confirm-modal",
  };

  open(_msg, _successCallback, opt = {}) {
    this.provider.$bvModal
      .msgBoxConfirm(_msg, { ...this.option, ...opt })
      .then((val) => {
        if (val && _successCallback) {
          _successCallback();
        }
        if (!val && opt["cancelCallback"]) {
          opt["cancelCallback"]();
        }
      })
      .catch((e) => {
        console.error("Thrown Error");
        console.dir(e);
      });
  }
}

<template>
  <div
    class="custom-control custom-checkbox"
    :class="[
      { disabled: disabled },
      { [`custom-checkbox-${type}`]: type },
      inlineClass,
      containerClass,
    ]"
  >
    <input
      :id="cbId"
      v-model="model"
      class="custom-control-input"
      :class="inputClasses"
      type="checkbox"
      :disabled="disabled || busy"
      :checked="checked"
      @change="onChange"
    />
    <label :for="cbId" class="custom-control-label">
      <slot>
        <span v-if="inline">
          <template v-if="labelText">{{ labelText }}</template>
          <template v-else>&nbsp;</template>
        </span>
      </slot>
    </label>
    <b-spinner
      v-if="busy"
      variant="success"
      style="width: 1rem; height: 1rem"
    ></b-spinner>
  </div>
</template>
<script>
export default {
  name: "BaseCheckbox",
  model: {
    prop: "checked",
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: "Whether checkbox is checked",
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
    inputClasses: {
      type: [String, Object, Array],
      description: "Checkbox input classes",
    },
    containerClass: {
      type: String,
      description: "Checkbox wrapper classes",
      default: "",
    },
    type: {
      type: String,
      description: "Checkbox type (e.g info, danger etc)",
    },
    labelText: {
      type: String,
      description: "Text Label",
    },

    busy: { type: Boolean, default: false },
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }

        this.$emit("input", check);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      } else {
        return false;
      }
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<template>
  <base-nav
    v-model="showMenu"
    type="light"
    menu-classes="justify-content-end"
    container-classes="container-fluid"
    class="navbar-horizontal navbar-main"
    expand="sm"
  >
    <div slot="brand" class="navbar-wrapper">
      <b-navbar-brand to="/flights">
        <img src="@assets/img/logo.jpg" />
      </b-navbar-brand>
    </div>

    <template>
      <div class="navbar-collapse-header">
        <b-row>
          <b-col cols="6" class="collapse-brand">
            <router-link to="/">
              <img src="img/brand/green.png" />
            </router-link>
          </b-col>
          <b-col cols="6" class="collapse-close">
            <button type="button" class="navbar-toggler" @click="closeMenu">
              <span></span>
              <span></span>
            </button>
          </b-col>
        </b-row>
      </div>

      <b-navbar-nav class="mr-auto">
        <!-- <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, _index) in link.children"
              :key="subLink.name + _index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot> -->
        <!-- <b-nav-item to="/flights">
          <i :class="'fa fa-plane'"></i>
          <span class="nav-link-inner--text">Flights</span>
        </b-nav-item> -->
        <base-nav-dropdown
          v-permission="[GRANT.LIST_LEG]"
          class="nav-item"
          tag="li"
          title-classes="nav-link"
          title-tag="a"
          :icon-link="'fa fa-plane'"
          :right="false"
          :no-caret="true"
          :href-link="'/flights'"
          :link="{ name: 'Flights', path: '/flights', children: [] }"
          menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
        >
          <b-dropdown-item>
            <b-nav-item to="/flight/immidiatetakeoff">
              <i :class="'ni ni-watch-time'"></i>
              <span class="nav-link-inner--text">Immediate Takeoff</span>
            </b-nav-item>
          </b-dropdown-item>
          <b-dropdown-item>
            <b-nav-item to="/flight/closedflights">
              <i :class="'fa fa-level-down-alt'"></i>
              <span class="nav-link-inner--text">Closed Flights</span>
            </b-nav-item>
          </b-dropdown-item>
          <b-dropdown-item>
            <b-nav-item to="/flight/modifiedflights">
              <i :class="'fa fa-edit'"></i>
              <span class="nav-link-inner--text">Modified Flights</span>
            </b-nav-item>
          </b-dropdown-item>
        </base-nav-dropdown>
        <b-nav-item v-permission="[GRANT.LIST_MEMBER]" to="/members">
          <i :class="'fa fa-users'"></i>
          <span class="nav-link-inner--text">Members</span>
        </b-nav-item>
        <b-nav-item v-permission="[GRANT.LIST_USERS]" to="/users">
          <i :class="'fa fa-users'"></i>
          <span class="nav-link-inner--text">Users</span>
        </b-nav-item>
        <b-nav-item v-permission="[GRANT.LIST_INVITE]" to="/invitation">
          <i :class="'fa fa-envelope'"></i>
          <span class="nav-link-inner--text">Invitations</span>
        </b-nav-item>
        <b-nav-item v-permission="[GRANT.LIST_AIRPORT]" to="/airports">
          <i :class="'fa fa-cube'"></i>
          <span class="nav-link-inner--text">Airports</span>
        </b-nav-item>
        <b-nav-item v-permission="[GRANT.EDIT_SETTINGS]" to="/settings">
          <i :class="'fa fa-cog'"></i>
          <span class="nav-link-inner--text">Settings</span>
        </b-nav-item>
        <b-nav-item v-permission="[GRANT.CAN_CHAT]" to="/support-chat">
          <i :class="'ni ni-headphones'"></i>
          <span class="nav-link-inner--text">Support Chat</span>
        </b-nav-item>
      </b-navbar-nav>
      <hr class="d-lg-none" />
      <b-navbar-nav class="align-items-lg-center ml-lg-auto">
        <base-dropdown
          menu-on-right
          class="nav-item"
          tag="li"
          title-tag="a"
          title-classes="nav-link pr-0"
        >
          <a
            slot="title-container"
            href="#"
            class="nav-link pr-0"
            @click.prevent
          >
            <b-media no-body class="align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="@assets/img/user-200.png" />
              </span>
              <b-media-body class="ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">
                  {{ fullName }}
                </span>
              </b-media-body>
            </b-media>
          </a>

          <template>
            <b-dropdown-header class="noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </b-dropdown-header>
            <!-- <b-dropdown-item href="#!">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </b-dropdown-item>
            <b-dropdown-item href="#!">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </b-dropdown-item> -->
            <div class="dropdown-divider"></div>
            <b-dropdown-item href="javascript:void(0)" @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </b-dropdown-item>
          </template>
        </base-dropdown>
        <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
        <!-- <li class="nav-item d-none d-lg-block ml-lg-4">
              <a
                href="https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro?ref=bvadp-index-page"
                target="_blank"
                class="btn btn-neutral btn-icon"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-shopping-cart mr-2"></i>
                </span>
                <span class="nav-link-inner--text">Purchase now</span>
              </a>
            </li> -->
      </b-navbar-nav>
    </template>
  </base-nav>
</template>
<script>
import { BaseNav, BaseNavDropdown } from "@components"; //
import { mapGetters } from "vuex";
import { GRANT } from "@/core/enums";

export default {
  components: {
    BaseNav,
    BaseNavDropdown,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      imgPath: require("@assets/img/user-200.png"),
      GRANT: GRANT,
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isActive() {
      /* if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      } */
      return true;
    },
    ...mapGetters("auth", ["user", "fullName"]),
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.replace("/login");
    },
    closeMenu() {
      console.log("closeMenu");
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-3">
          <h1 class="text-white">Welcome!</h1>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-4">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div class="btn-wrapper text-center">
                <img width="130px" src="@assets/img/logo.jpg" />
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    v-model="formGroup.email"
                    alternative
                    class="mb-3"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                  ></base-input>

                  <base-input
                    v-model="formGroup.password"
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                  ></base-input>

                  <!-- <b-form-checkbox v-model="model.rememberMe">
                    Remember me
                  </b-form-checkbox> -->
                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      :loading="loading"
                    >
                      Sign in
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <!-- <b-col cols="12" class="text-right">
              <router-link to="/register" class="text-light">
                <small>Create new account</small>
              </router-link>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { notificationService } from "@/core/services";
// import { authService } from "@core/services";

//import { notificationService } from "@core/services";

export default {
  data() {
    return {
      loading: false,
      formGroup: {
        email: null, //"admin@flygi.com",
        password: null, //"12345678",
        //rememberMe: false,
      },
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", this.formGroup)
        .then((res) => {
          notificationService.success(res.message);
          this.$router.replace("/flights");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

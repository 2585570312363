<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4"></b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body class="border-0">
            <div class="container py-5">
              <div class="row forbidden">
                <div class="col-md-2 text-center forbidden__img">
                  <div>
                    <i class="warn-icon fa fa-exclamation-triangle fa-5x"></i>
                    <div class="status">Status Code: 403</div>
                  </div>
                </div>
                <div class="col-md-10 forbidden__desc">
                  <h2 class="main-heading">OPPSSS!!!! Sorry...</h2>
                  <div class="para">
                    Sorry, your access is refused due to security reasons of our
                    server and also our sensitive data.
                  </div>
                  <div class="para">
                    Please go back to the previous page to continue browsing.
                  </div>
                  <div class="action-btn">
                    <router-link to="/flights" class="btn btn-primary back-btn">
                      Back to Home
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Forbidden",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.card {
  background: #f6e7e7;
  border: 1px solid #e3c0c0 !important;
}
.forbidden {
  &__img {
    .warn-icon {
      color: #eb1414;
    }
    .status {
      margin-top: 1em;
      color: #856868;
      font-weight: 600;
      font-size: 1.13em;
    }
  }
  &__desc {
    padding-left: 2.5em;
    .main-heading {
      font-size: 1.45rem;
    }
    .para {
      font-size: 0.97em;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .action-btn {
      margin-top: 2.5em;
    }
    .back-btn {
      border-color: #e94941;
      background-color: #e94941;
      &:hover {
        background-color: #e94941;
      }
    }
  }
}
</style>

<template>
  <div class="base-image">
    <img v-if="src" :class="[styleClass, { busy: busy }]" :src="src" alt="" />
    <img
      v-else
      :class="[styleClass, { busy: busy }]"
      src="@assets/img/no-img.jpg"
    />
    <span v-if="busy" class="base-spinner">
      <b-spinner variant="success"></b-spinner>
    </span>
  </div>
</template>

<script>
export default {
  name: "BaseImage",
  props: {
    styleClass: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.base-image {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 9;
  img {
    &.busy {
      opacity: 0.4;
    }
  }
  .base-spinner {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: 10;
  }
}
</style>

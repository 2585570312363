import { authService } from "../services";

import store from "@store";

const authGaurd = async (_to, _from, next) => {
  if (authService.getJwtToken()) {
    try {
      const auth = store.getters["auth/user"];
      if (auth) {
        next();
      } else {
        await store.dispatch("auth/authenticate");
        next();
      }
    } catch (e) {
      store.dispatch("auth/logout");
      next("/login");
    }
  } else {
    next("/login");
  }
};

export default authGaurd;

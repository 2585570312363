import BaseModel from "./BaseModel";

export class MemberModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      display_id: undefined,
      member_id: undefined,
      member_display_id: undefined,
      first_name: undefined,
      last_name: undefined,
      full_name: undefined,
      email: undefined,
      phone: undefined,
      total_hours_travelled: undefined,
      joined_at: undefined,
      shadow_user_count: undefined,
      rewards_balance: undefined,
      user_billing: undefined,
      shadow_user: [],
      last_flights: [],
      is_active: undefined,
      invited_by: [],
      rewards: [],
      invitations: [],
    };
  }
}

import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseCard from "@/components/cards/BaseCard.vue";
import Modal from "@/components/Modal.vue";
import StatsCard from "@/components/cards/StatsCard.vue";
import BaseNav from "@/components/navbar/BaseNav";
import BaseButton from "@/components/BaseButton.vue";
import Badge from "@/components/Badge.vue";
import RouteBreadcrumb from "@/components/breadcrumb/RouteBreadcrumb";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseRadio from "@/components/inputs/BaseRadio";
import BaseSelectbox from "@/components/inputs/BaseSelectbox";
import BaseDatepicker from "@/components/inputs/BaseDatepicker";
import BaseEditor from "@/components/inputs/BaseEditor";
import BasePopover from "@/components/BasePopover";
import BaseTooltip from "@/components/BaseTooltip";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseHeader from "@/components/BaseHeader";
import BaseImage from "@/components/BaseImage";
import BaseProgress from "@/components/BaseProgress";
import ProgressLoader from "@/components/ProgressLoader";
import DataTable from "@/components/data-table/DataTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// ? import { Input, Tooltip, Popover } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseSelectbox.name, BaseSelectbox);
    Vue.component(BaseDatepicker.name, BaseDatepicker);
    Vue.component(BaseEditor.name, BaseEditor);
    Vue.component(BasePopover.name, BasePopover);
    Vue.component(BaseTooltip.name, BaseTooltip);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(BaseCard.name, BaseCard);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(DataTable.name, DataTable);
    Vue.component(BaseImage.name, BaseImage);
    Vue.component(ProgressLoader.name, ProgressLoader);
    //Vue.component(Input.name, Input);
    Vue.component("validation-provider", ValidationProvider);
    Vue.component("validation-observer", ValidationObserver);
    //Vue.use(Tooltip);
    //Vue.use(Popover);
  },
};

export default GlobalComponents;

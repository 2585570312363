import { BaseService } from "./base.service";

import { AirportModel } from "@/core/models";

export class AirportService extends BaseService {
  fetchAll() {
    return this.get("/airports/details").then((res) => {
      const data = res.data.map((item) => {
        item.status = item.is_active ? "active" : "inactive";
        // item.default_img = "src/assets/img/default-image.png";
        return new AirportModel(item);
      });
      this.$store.commit("airport/setList", data);

      return {
        ...res,
        data,
      };
    });
  }

  save(payload) {
    return this.post("/airports", payload).then((res) => {
      const data = res.data;
      data.status = data.is_active ? "active" : "inactive";
      this.$store.commit("airport/addNew", data);
      return res;
    });
  }

  remove(airportID) {
    return this.delete(`/airports/${airportID}`).then((res) => {
      return res;
    });
  }

  uploadImage(airportID, file) {
    let formData = new FormData();
    formData.append("image", file);

    return this.post(`/airports/upload/${airportID}`, formData, {
      "Content-Type": "multipart/form-data",
    }).then((res) => {
      this.updateState(airportID, {
        image_url: res.data.image_url,
      });
      return res;
    });
  }

  saveNote(airportID, payload) {
    return this.post(`/airports/saveComment/${airportID}`, payload).then(
      (res) => {
        this.$store.commit("airport/updateNote", res.data);
        return res;
      }
    );
  }

  saveAttribute(airportID, field) {
    const payload = {
      //map_location: link,
      ...field,
    };

    return this.put(`/airports/saveAttribute/${airportID}`, payload).then(
      (res) => {
        this.updateState(airportID, payload);
        return res;
      }
    );
  }

  updateState(recordID, field) {
    this.$store.commit("airport/updateField", {
      id: recordID,
      field: {
        ...field,
      },
    });
  }

  drop(airportID) {
    this.$store.commit("airport/remove", airportID);
  }

  fetchAllUsers() {
    return this.get("/users/firebaseUsers").then((res) => {
      const data = res.data;
      this.$store.commit("chat/setUsers", data);

      return {
        ...res,
        data,
      };
    });
  }
}

import { SETTING } from "@/core/enums";
import { SettingtModel } from "@/core/models";

const settingModel = new SettingtModel();

const initialState = () => {
  return {
    [SETTING.PRICING]: {
      editable: false,
      model: settingModel[SETTING.PRICING],
    },
    [SETTING.BOOKING]: {
      editable: false,
      model: settingModel[SETTING.BOOKING],
    },
    [SETTING.INVITATION]: {
      editable: false,
      model: settingModel[SETTING.INVITATION],
    },
    [SETTING.TOKEN]: {
      editable: false,
      model: settingModel[SETTING.TOKEN],
    },
    [SETTING.INFORMATIONPOPUP]: {
      editable: false,
      model: settingModel[SETTING.INFORMATIONPOPUP],
    },

    [SETTING.OTHER]: {
      model: settingModel[SETTING.OTHER],
    },
    [SETTING.TEXT]: {
      model: settingModel[SETTING.TEXT],
    },
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    isEditable(state) {
      const prop = {};

      Object.keys(SETTING).forEach((settingType) => {
        const key = SETTING[settingType];
        prop[key] = state[key].editable;
      });

      return prop;
    },
    details(state) {
      const pref = {};

      Object.keys(SETTING).forEach((settingType) => {
        const key = SETTING[settingType];
        pref[key] = state[key].model;
      });

      return pref;
    },
    findDetails(state) {
      return (prefKey) => state[prefKey];
    },
  },
  mutations: {
    toggleEdit(state, payload) {
      const settingType = payload.type;
      const editable = payload.editable;

      state[settingType].editable = editable;
    },
    resetAllEditMode(state) {
      Object.keys(SETTING).forEach((settingType) => {
        const key = SETTING[settingType];
        state[key].editable = false;
      });
    },
    setDetails(state, payload) {
      const settings = payload;

      Object.keys(settings).forEach((prefKey) => {
        state[prefKey].model = {
          ...state[prefKey].model,
          ...settings[prefKey],
        };
      });
    },
    updateOtherSettings(state, payload) {
      state[SETTING.OTHER].model = payload;
    },
  },
  actions: {},
};

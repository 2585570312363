<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Overview</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
      </b-row>
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card
            title="Total traffic"
            type="gradient-red"
            sub-title="350,897"
            icon="ni ni-active-40"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                3.48%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col v-permission="['addqw-airport', 'add-flight']" md="6" xl="3">
          <stats-card
            title="Total traffic"
            type="gradient-orange"
            sub-title="2,356"
            icon="ni ni-chart-pie-35"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                12.18%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col md="6" xl="3">
          <stats-card
            title="Sales"
            type="gradient-green"
            sub-title="924"
            icon="ni ni-money-coins"
          >
            <template slot="footer">
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i>
                5.72%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col md="6" xl="3">
          <stats-card
            title="Performance"
            type="gradient-info"
            sub-title="49,65%"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i>
                54.8%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body class="border-0">
            <div
              id="map"
              class="map-canvas"
              style="
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <h2>Our Dashboard</h2>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadCrumb, StatsCard } from "@/components";

export default {
  name: "Dashboard",
  components: {
    StatsCard,
    RouteBreadCrumb,
  },
  data() {
    return {
      users: [
        {
          id: 1,
          name: "John Michael",
          image: "img/theme/team-1.jpg",
          createdAt: "10/09/2018",
          product: "Argon Dashboard PRO",
          active: true,
        },
        {
          id: 2,
          name: "Alexandra Smith",
          image: "img/theme/team-2.jpg",
          createdAt: "08/09/2018",
          product: "Argon Design System",
          active: false,
        },
        {
          id: 3,
          name: "Samantha Ivy",
          image: "img/theme/team-3.jpg",
          createdAt: "30/08/2018",
          product: "Black Dashboard",
          active: false,
        },
        {
          id: 4,
          name: "John Michael",
          image: "img/theme/team-1.jpg",
          createdAt: "10/09/2018",
          product: "Argon Dashboard PRO",
          active: true,
        },
        {
          id: 5,
          name: "Alexandra Smith",
          image: "img/theme/team-2.jpg",
          createdAt: "30/09/2018",
          product: "Vue Argon Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<style></style>

import axios from "axios";

import { APP_CONFIG } from "@/config";

import { JWTService } from "./jwt.service";

class HttpClient {
  constructor() {
    this.instance = axios.create({
      baseURL: APP_CONFIG.apiUrl,
    });

    return this.init();
  }

  init() {
    if (JWTService.getToken()) {
      this.instance.interceptors.request.use((request) => {
        request.headers.Authorization = JWTService.bearerToken;
        return request;
      });
    }
    return this.instance;
  }
}

export default new HttpClient();

const initialState = () => {
  return {
    usersList: [],
  };
};

export default {
  namespaced: true,
  state() {
    return initialState();
  },
  getters: {
    usersList(state) {
      return state.usersList;
    },
  },
  mutations: {
    setList(state, payload) {
      state.usersList = payload;
    },
    addNew(state, payload) {
      state.usersList = [...state.usersList, payload];
    },
    remove(state, recordID) {
      const idx = state.usersList.findIndex((item) => item.id == recordID);
      if (idx != -1) {
        state.usersList.splice(idx, 1);
      }
    },
  },
};

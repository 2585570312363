<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Support Chat
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <main class="content">
        <div class="container p-0">
          <div class="card">
            <div class="row m-0">
              <div
                class="col-12 col-lg-5 col-xl-3 border-right contact-list-main"
              >
                <contact-list></contact-list>
              </div>
              <div
                class="col-12 col-lg-7 col-xl-9 room-message-main"
                :class="{ activated: activatedUser }"
              >
                <chat-message ref="chatMessager"></chat-message>
              </div>
            </div>
          </div>
        </div>
      </main>
    </b-container>
  </div>
</template>

<script>
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import { RouteBreadCrumb } from "@/components";

import ChatMessage from "./components/ChatMessage.vue";
import ContactList from "./components/ContactList.vue";

import { firebaseService } from "@/core/services";
import { mapGetters } from "vuex";
/* 
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 400);
  }
} */

export default {
  name: "SupportChat",
  components: { RouteBreadCrumb, ContactList, ChatMessage },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("chat", ["pickedUser"]),
    activatedUser() {
      return this.pickedUser ? false : true;
    },
  },
  mounted() {
    firebaseService.getMessages();
    this.initScrollbar();
  },
  destroyed() {
    this.clearUserSelection();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // initScrollbar("chat-messages");
        // initScrollbar("user--list");
      }
    },
    clearUserSelection() {
      this.$store.commit("chat/chosenUser", null);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .card {
    border: 1px solid #e2dbdb;
    .contact-list-main {
      padding: 0;
      .search-input {
        border: 1px solid #cec1c1;
      }
      .no-user-list {
        padding-top: 1.5em;
        padding-left: 2em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
        font-size: 0.8em;
        font-weight: 600;
        span {
          border-bottom: 1px solid #847e7e;
          text-transform: uppercase;
          letter-spacing: 0.2px;
        }
      }
    }
    .room-message-main {
      padding: 0;
      border-radius: 0.375rem;
      border: 0;
      &.activated {
        background: #f7fafa;
      }
      .username-heading {
        strong {
          text-transform: capitalize;
          font-size: 1.1em;
        }
      }
      .chat-message-header {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.35em;
        padding-bottom: 0.5em;
      }
    }
  }
  .chat-online {
    color: #34ce57;
  }

  .chat-offline {
    color: #e4606d;
  }

  .chat-messages {
    display: flex;
    flex-direction: column;
    height: 800px;
    // overflow-y: auto;
  }

  .chat-message-left,
  .chat-message-right {
    display: flex;
    flex-shrink: 0;
  }

  .chat-message-left {
    margin-right: auto;
  }

  .chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
  }
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .text-msg-container {
    .input-group {
      align-items: center;
      .img-msg-container {
        width: 50px;
        .img-msg_content {
          width: 35px;
          height: 35px;
          background: #2673d3;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          span {
            color: #fff;
          }
        }
      }
    }
    .img-msg-container {
      .chat-img-upload-input {
        display: none;
      }
    }
    .chat--input {
      border-top-left-radius: 0.25em !important;
      border-bottom-left-radius: 0.25em !important;
      height: calc(1.5em + 1.0135rem + 5px);
    }
    .chat--input-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    // #4b5274
  }
  .user--img {
    border: 1px solid #c5bcbc;
  }
  .user--list {
    height: 880px;
    border-top: 1px solid #e9ecef;
    &_item {
      cursor: pointer;
      .item-title {
        font-size: 0.9em;
        margin-left: 0.35em;
        color: #4c4545;
      }
      .unread-count {
        color: #fff;
        font-weight: 600;
        font-size: 0.75em;
        padding: 0.25rem 0.35rem;
      }
      &:hover {
        background-color: #e4eef5 !important;
        z-index: 1;
        position: relative;
      }
      &--active {
        background-color: #b2d4ff; //#b2d4ff
        border: 1px solid #c2d0d1 !important;
        border-left: 4px solid #6f9dbe !important;
        .item-title {
          color: #5c5656 !important;
          font-weight: 600;
        }
        &:hover {
          background-color: #b2d4ff !important;
        }
      }
    }
  }

  .chat-message-main {
    margin-bottom: 1.8em;
    &.chat-message-right {
      .txt-date {
        text-align: left;
      }
      .txt-content-wrapper {
        margin-right: 1em;
      }
    }
    &.chat-message-left {
      .txt-date {
        text-align: right;
      }
      .txt-content-wrapper {
        margin-left: 1em;
      }
    }
    .txt-wrapper {
      display: flex;
      flex-direction: column;
      .txt-content-wrapper {
        background-color: #ced4da;
        border-radius: 0.375rem;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 1em;
        padding-left: 1em;
        .user-message--title {
          color: #4b5274;
          font-weight: 700 !important;
          margin-bottom: 0.8em;
          font-size: 0.95em;
        }
        .txt-content {
          font-size: 0.93em;
          .txt-content-img {
            width: 230px;
            img {
              margin-top: 0.5em;
              width: 100%;
            }
          }
        }
      }
      .txt-date {
        font-size: 0.77em;
        color: #8f8d8d;
        margin-top: 0.2em;
        letter-spacing: 0.4px;
      }
    }
  }

  .no-chat-selection {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &_img {
    }
    &_heading {
      font-size: 2.5em;
      margin-bottom: 1.5em;
      border-bottom: 3px solid #4aaec2;
      color: #4aaec2;
      font-weight: 600;
    }
    &_txt {
      margin-top: 1.5em;
      font-size: 1.2em;
      font-weight: 600;
      color: #a29b9b;
    }
  }

  /* .ps {
    &__rail-y {
      &:hover {
        z-index: 222 !important;
        position: relative;
      }
    }
  } */
  .ps--active-y > .ps__rail-y {
    z-index: 2;
  }
}
</style>

import { BaseService } from "./base.service";

import { FlightModel } from "@/core/models";
import { DateTime } from "@/core/utils";
export class FlightService extends BaseService {
  name = "API_AUTH";

  bookings(_payload) {
    return this.get(
      "/bookings?excludeStatus[0]=pending&excludeStatus[1]=completed&excludeStatus[2]=cancelled"
    ).then((res) => {
      const data = res.data.map((item) => {
        let value = new FlightModel(item);

        if (value) {
          item = { ...item };

          if (item.booking_details.length) {
            let is_live = item.booking_details.find((r1) => r1.is_live == true);

            if (is_live) {
              item.is_live = is_live;
            }

            return item;
          }
        }
      });
      this.$store.commit("flight/setList", data);
      return {
        ...res,
        data,
      };
    });
  }
  save_ferry(payload) {
    let takeoff_time = DateTime.format(
      payload.date,
      "YYYY-MM-DD HH:mm",
      "YYYY-MM-DD  HH:mm A"
    );

    return this.get(
      `airports/data?from=` +
        payload.from_location +
        `&to=` +
        payload.to_location +
        `&takeoff_time=` +
        takeoff_time
    ).then((res) => {
      let airport_data = res.data;

      let obj = {
        user_id: payload.user,
        legs: [
          {
            from: payload.from_location,
            to: payload.to_location,
            departure_time: takeoff_time,
            arrival_time: DateTime.format(
              airport_data.landing_time,
              "YYYY-MM-DD HH:mm"
            ),
            duration: airport_data.duration,
          },
        ],
      };

      return this.post("bookings/saveFlightLeg", obj).then((res) => {
        const data = res.data[0];

        if (data.booking_details.length) {
          let is_live = data.booking_details.find((r1) => r1.is_live == true);
          if (is_live) {
            data.is_live = is_live;
          }
        }
        this.$store.commit("flight/addNew", data);
        return res;
      });
    });

    // return this.post("/saveFlightLeg", payload).then((res) => {
    //   const data = res.data;
    //   this.$store.commit("flight/addNew", data);
    //   return res;
    // });
  }
  changeAddons(id, payload) {
    return this.put(`/bookings/details/saveAttribute/${id}`, payload);
  }
  changeDeparture(id, payload) {
    let obj = {
      departure_time: DateTime.utc(payload, "YYYY-MM-DD HH:mm"),
    };

    return this.put(`/bookings/changeDeparture/${id}`, obj).then((res) => {
      const data = res.data;
      this.$store.commit("flight/updateField", {
        id: id,
        field: {
          ...data,
        },
      });
      return res;
    });
  }

  changeDestination(id, payload) {
    let obj = {
      to: payload,
    };

    return this.put(`/bookings/changeDestination/${id}`, obj).then((res) => {
      const data = res.data;
      console.log(data, "data");
      this.$store.commit("flight/updateField", {
        id: id,
        field: {
          ...data,
        },
      });
      return res;
    });
  }

  changeStatus(id, payload) {
    let obj = {
      status: payload,
    };

    return this.put(`/bookings/changeStatus/${id}`, obj).then((res) => {
      return res;
    });
  }

  saveNote(airportID, payload) {
    return this.post(`/bookings/saveComment/${airportID}`, payload);
  }
}

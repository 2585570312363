export const FB_NOTIFICATION_TYPE = {
  IMMEDIATE_BOOKING_CONFIRMED: "immediate_booking_confirmed",
  IMMEDIATE_BOOKING_CANCELLED: "immediate_booking_cancelled",
  REGULAR_BOOKING_UPDATED: "regular_booking_updated",
  IMMEDIATE_BOOKING_CLOSED: "immediate_booking_confirmed_closed",
  IMMEDIATE_BOOKING_ACTION: "immediate_booking_action",
};

export const FIREBASE_MESSAGE = {
  IMMEDIATE_BOOKING_CONFIRMED: {
    title: "Flygi - Booking Confirmation",
    body: "Your Immediate booking #{BOOKING_ID} has been confirmed successfully",
  },
  IMMEDIATE_BOOKING_CANCELLED: {
    title: "Flygi - Booking Cancellation",
    body: "Your Immediate booking #{BOOKING_ID} has been cancelled successfully",
  },
  REGULAR_BOOKING_UPDATED: {
    title: "Flygi - Booking Update",
    body: "Your requested booking #{BOOKING_ID} has been updated successfully",
  },
};

import { APP_CONFIG } from "@/config";

import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
// import { getMessaging } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";
import { ChatModel } from "../../models/ChatModel";
import { DateTime, Helper } from "../../utils";
import { BaseService } from "./base.service";
import { JWTService } from "./jwt.service";

import {
  BOOKING_STATUS,
  FB_NOTIFICATION_TYPE,
  FIREBASE_MESSAGE,
} from "@/core/enums";
import { alertService } from "..";
import router from "@/router";

const firebaseApp = firebase.initializeApp(APP_CONFIG.firebase.access);

export class FirebaseService extends BaseService {
  instance;
  fbAuth;
  firestore;
  subscriber;
  firebaseMessaging;
  serviceWokerMessaging;
  fcmtoken;

  constructor() {
    super();
    this.instance = firebase;
    this.appInstance = firebaseApp;
    this.firebaseAuth = firebase.auth();
    this.firestore = firebase.firestore();
    this.serviceWokerMessaging = getMessaging();
    this.firebaseMessaging = firebase.messaging();
    this.subscriber = null;
    this.fcmtoken = "";
    this.subscribeRealTimeMessage();
    // this.sendNotification();
  }

  get userUID() {
    const sess = JWTService.getToken();
    return (sess && sess.external_uid) || null;
  }

  login() {
    const email = APP_CONFIG.firebase.support.username;
    const password = APP_CONFIG.firebase.support.password;

    return this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log(res);
        return res.user;
      });
  }

  authenticate() {
    const promise = new Promise((resolve, reject) => {
      this.firebaseAuth.onAuthStateChanged(
        (user) => {
          if (user) {
            resolve(user);
          }
        },
        (e) => reject(e)
      );
    });
    return promise;
  }

  logout() {}

  sendMessage(payload) {
    // const createdAt = DateTime.format(null, "DD-MM-YYYY HH:mm:ss");
    const data = {
      id: this.userUID,
      message: payload.message,
      time: DateTime.parseDate().toDate(),
      read: true,
    };

    return this.firestore
      .collection("support_chats")
      .doc(payload.sendTo)
      .collection("messages")
      .add(data)
      .then((doc) => {
        return doc;
      });
  }

  updateFirebaseDetails(id, payload) {
    let obj = {
      firebase_id: id,
      fcm_token: payload,
    };
    return this.put("/firebase/user", obj).then((res) => {
      return {
        ...res,
        res,
      };
    });
  }

  getMessages() {
    /* 
      .onSnapshot((querySnapshot) => {
        console.log("querySnapshot", querySnapshot);
        querySnapshot.forEach((doc) => {
          doc.ref
            .collection("messages")
            .onSnapshot((abc) => {
              abc.forEach((_doc) => {
                console.log("Child DATA", _doc.data());
              });
            });
          console.log("PARENT DATA", doc.data());
        });
      });
 */
    this.fetchAllUsers().then((users) => {
      users.forEach((user) => {
        const userMessageCollectionRef = this.firestore
          .collection("support_chats")
          .doc(user.firebase_id)
          .collection("messages");

        userMessageCollectionRef
          .orderBy("time", "asc")
          .onSnapshot((collection) => {
            /* const source = collection.metadata.hasPendingWrites
              ? "Local"
              : "Server"; */
            const docs = collection.docs;
            const chatMessages = [];

            docs.forEach((doc, idx) => {
              const messageDocument = doc.data();
              chatMessages.push(new ChatModel(user, messageDocument, idx));
            });

            this.$store.commit("chat/setUserMessages", {
              chatMessages,
              user,
              firebase_id: user.firebase_id,
            });

            const selectedUserMessage =
              this.$store.getters["chat/selectedUserMessages"];

            if (
              selectedUserMessage &&
              selectedUserMessage.chatUserUid == user.firebase_id
            ) {
              this.markReadSelectedUserMessages(user.firebase_id);
            }
          });
      });
    });
  }

  fetchAllUsers() {
    return this.get("/users/firebaseUsers").then((res) => {
      const users = res.data;
      this.$store.commit("chat/setUsers", users);
      return users;
    });
  }

  removeSubscription() {
    if (this.subscriber) {
      this.subscriber();
    }
  }

  markReadSelectedUserMessages(firebaseUserID) {
    const userRef = this.firestore
      .collection("support_chats")
      .doc(firebaseUserID);
    const userMessageCollectionRef = this.firestore
      .collection("support_chats")
      .doc(firebaseUserID)
      .collection("messages")
      .where("read", "==", false)
      .get();

    userMessageCollectionRef
      .then((resp) => {
        this.firestore
          .runTransaction((transaction) => {
            return transaction.get(userRef).then((_userRef) => {
              const dataset = [];
              resp.docs.forEach((userDocRef) => {
                transaction.update(userDocRef.ref, {
                  read: true,
                });
                dataset.push(userDocRef.data());
                /* userDocRef.ref.update({
              read: false,
            }).then(()); */
              });
              return dataset;
            });
          })
          .then((_dataset) => {
            this.$store.commit("chat/readMarkMessages");
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  subscribeRealTimeMessage() {
    this.firebaseMessaging.onMessage((payload) => {
      console.log("Receiving foreground message");
      console.log(payload);
      let bookingID = (payload.data && payload.data.booking_id) || null;
      let status;

      if (payload.data && payload.data.type) {
        if (
          payload.data.type == FB_NOTIFICATION_TYPE.IMMEDIATE_BOOKING_ACTION
        ) {
          alertService
            .immediateConfirmation({
              title: payload.notification.title,
              body: payload.notification.body,
              confirmBtnText: "View Details",
              // cancelBtnText: "Reject",
              showDenyButton: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                status = BOOKING_STATUS.PILOT_CONFIRMED;
              } else if (result.isDismissed) {
                status = BOOKING_STATUS.PILOT_REJECTED;
                console.log("Rejected");
              }

              if (status == BOOKING_STATUS.PILOT_CONFIRMED) {
                router.push({
                  path: "/flight/immidiatetakeoff",
                  query: { booking_id: bookingID },
                });
                /* this.put(`/bookings/changeStatus/${bookingID}`, {
                  status: status,
                }).then((_) => {
                  router.push({
                    path: "/immidiatetakeoff",
                    query: { booking_id: bookingID },
                  });
                }); */
              }
            });
        } else if (
          [FB_NOTIFICATION_TYPE.IMMEDIATE_BOOKING_CLOSED].indexOf(
            payload.data.type
          ) != -1
        ) {
          alertService.close();
        }
      }

      /* var notificationTitle = payload.notification.title;
      var notificationOptions = {
        body: payload.notification.body,
        icon: "",
      };
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      console.log("notification", notification);
      notification.onclick = function (event) {
        notification.close();
        console.log(event);
      }; */
    });
  }

  getFCMToken() {
    this.firebaseMessaging
      .getToken()
      .then((token) => {
        // console.log("__token", token);
        this.fcmtoken = token;
        this.updateFirebaseDetails(
          APP_CONFIG.firebase.support.firebaseUid,
          token
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }

  sendNotification(sendToTokenIdentity, data, type) {
    const bookingType = { ...FIREBASE_MESSAGE[type] };

    bookingType.body = Helper.textReplace(
      bookingType.body,
      "{BOOKING_ID}",
      data.booking_id
    );

    axios
      .request({
        url: "https://fcm.googleapis.com/fcm/send",
        method: "POST",
        data: {
          notification: {
            title: bookingType.title,
            body: bookingType.body,
          },
          to: sendToTokenIdentity,
          data: data,
        },
        headers: {
          Authorization: `key=${APP_CONFIG.firebase.serverKey}`,
        },
      })
      .then((_) => {})
      .finally(() => {});
  }
  logoutFcm() {
    this.firebaseMessaging.deleteToken();
  }
}

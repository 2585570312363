// import PERMISSION from "@/core/enums";

import store from "@store";

const hasPermissionGuard = async (_to, _from, next) => {
  let allowedPermissions = store.getters["auth/permissions"];
  let routePermissions = (_to.meta && _to.meta.permissions) || [];
  let foundPermission;

  if (routePermissions.length == 0) {
    return next();
  }

  routePermissions.some((selectedPermissionModule) => {
    const foundActionKey = Object.keys(selectedPermissionModule.action).find(
      (actionKey) => {
        const isAllowed = allowedPermissions.find(
          (item) => item == selectedPermissionModule.action[actionKey]
        );
        return isAllowed ? true : false;
      }
    );

    if (foundActionKey) {
      foundPermission = selectedPermissionModule.action[foundActionKey];
      return true;
    } else {
      return false;
    }
  });

  if (foundPermission) {
    next();
  } else {
    next("/forbidden");
  }
};

export default hasPermissionGuard;

export const GRANT = {
  ADD_AIRPORT: "add-airport",
  DELETE_AIRPORT: "delete-airport",
  EDIT_AIRPORT: "edit-airport",
  ADD_AIRPORT_IMAGE: "add-airport-image",
  LIST_AIRPORT: "list-airports",
  LIST_LEG: "list-legs",
  CHANGE_LEG_STATUS: "change-leg-status",
  ADD_LEG: "add-leg",
  EDIT_LEG: "edit-leg",
  LEG_ADDONS: "legs-addons",
  LIST_USERS: "list-users",
  ADD_USER: "add-user",
  EDIT_USER: "edit-users",
  UPDATE_USER: "update-user",
  DELETE_USER: "delete-user",
  LIST_INVITE: "list-invite",
  ADD_INVITE: "add-invite",
  DELETE_INVITE: "delete-invite",
  RESEND_INVITE: "resend-invite",
  LIST_MEMBER: "list-member",
  ADD_MEMBER: "add-member",
  EDIT_MEMBER: "update-member",
  EDIT_MEMBER_STATUS: "update-member-status",
  ADD_MEMBER_INVITE: "add-member-invite",
  ADD_MEMBER_TOKEN: "add-member-token",
  EDIT_SETTINGS: "settings",
  CAN_CHAT: "chat",
};

export const PERMISSION = {
  AIRPORT: {
    action: {
      ADD: GRANT.ADD_AIRPORT,
      DELETE: GRANT.DELETE_AIRPORT,
      EDIT: GRANT.EDIT_AIRPORT,
      ADD_IMAGE: GRANT.ADD_AIRPORT_IMAGE,
      LIST: GRANT.LIST_AIRPORT,
    },
    name: "airports",
  },
  FLIGHT: {
    action: {
      LIST_LEG: GRANT.LIST_LEG,
      CHANGE_LEG_STATUS: GRANT.CHANGE_LEG_STATUS,
      ADD_LEG: GRANT.ADD_LEG,
      EDIT_LEG: GRANT.EDIT_LEG,
      LEG_ADDONS: GRANT.LEG_ADDONS,
    },
    name: "flight",
  },
  USER: {
    action: {
      LIST: GRANT.LIST_USERS,
      ADD: GRANT.ADD_USER,
      EDIT: GRANT.EDIT_USER,
      UPDATE: GRANT.UPDATE_USER,
    },
    name: "users",
  },
  INVITE: {
    action: {
      LIST: GRANT.LIST_INVITE,
      ADD: GRANT.ADD_INVITE,
      DELETE: GRANT.DELETE_INVITE,
      RESEND: GRANT.RESEND_INVITE,
    },
    name: "invite",
  },
  MEMBER: {
    action: {
      LIST: GRANT.LIST_MEMBER,
      ADD: GRANT.ADD_MEMBER,
      EDIT: GRANT.EDIT_MEMBER,
      EDIT_STATUS: GRANT.EDIT_MEMBER_STATUS,
      ADD_INVITE: GRANT.ADD_MEMBER_INVITE,
      ADD_TOKEN: GRANT.ADD_MEMBER_TOKEN,
    },
    name: "member",
  },
  SETTING: {
    action: {
      ALL: GRANT.EDIT_SETTINGS,
    },
    name: "settings",
  },
  CHAT: {
    action: {
      ALL: GRANT.CAN_CHAT,
    },
    name: "chat",
  },
};

export default PERMISSION;

import { APP_CONFIG } from "@/config";

export class LocalStorageService {
  storage;
  prefix;

  constructor() {
    this.storage = localStorage;
    this.prefix = APP_CONFIG.prefix;
  }

  prefixKey(key) {
    return this.prefix + String(key);
  }

  set(key, value) {
    return this.storage.setItem(this.prefixKey(key), JSON.stringify(value));
  }

  get(key, defaultValue = null) {
    let storedValue = null;
    const item = this.storage.getItem(this.prefixKey(key));
    const val = item ? true : false;
    // eslint-disable-next-line no-extra-boolean-cast
    //? if(!!item)
    if (val) {
      storedValue = parseJSON(item);
    }
    return storedValue === null ? defaultValue : storedValue;
  }

  remove(key) {
    return this.storage.removeItem(this.prefixKey(key));
  }

  clear(force = false) {
    if (force) {
      this.storage.clear();
    } else {
      this.keys(true).map((key) => {
        this.storage.removeItem(key);
      });
    }
  }

  keys(withPrefix = false) {
    const keys = [];

    // Loop through all storage keys
    Object.keys(this.storage).forEach((keyName) => {
      /* istanbul ignore else */
      if (keyName.substr(0, this.prefix.length) === this.prefix) {
        keys.push(withPrefix ? keyName : keyName.substring(this.prefix.length));
      }
    });

    return keys;
  }

  hasKey(key) {
    return this.keys().indexOf(key) !== -1;
  }

  length() {
    return this.keys().length;
  }
}

const parseJSON = (value) => {
  if (value === null) {
    return value;
  }
  return JSON.parse(value);
};

export const BOOKING_STATUS = {
  PENDING: "pending",
  CONFIRMED: "confirmed",
  PILOT_CONFIRMED: "pilot_confirmed",
  PILOT_REJECTED: "pilot_rejected",
  CANCELLED: "cancelled",
  STARTED: "started",
  WAITING: "waiting",
  COMPLETED: "completed",
};

import { BaseService } from "./base.service";
import { MemberModel } from "@/core/models";
import { UsersModel } from "@/core/models";

export class MembersService extends BaseService {
  name = "API_AUTH";

  members() {
    return this.get("/users/member").then((res) => {
      const data = res.data.map((item) => {
        return new MemberModel(item);
      });

      this.$store.commit("member/setList", data);
      return {
        ...res,
        data,
      };
    });
  }

  firebaseusers() {
    return this.get("/users/firebaseUsers").then((res) => {
      // const data = res.data.map((item) => {
      //   return new UsersModel(item);
      // });

      return {
        ...res,
        res,
      };
    });
  }

  saveReward(payload) {
    return this.post("/rewards", payload).then((res) => {
      return res;
    });
  }

  saveUser(payload) {
    return this.post("/users", payload).then((res) => {
      const data = res.data;

      this.$store.commit("member/addUser", data);

      return res;
    });
  }

  editUser(id, payload) {
    return this.put(`/users/${id}`, payload).then((r2) => {
      const data = r2.data;

      this.$store.commit("member/updateField", {
        id: id,
        field: {
          ...data,
        },
      });
      return r2;
    });
  }
  attribute(id, payload) {
    return this.put(`/users/editAttribute/${id}`, {
      vat_status: payload.vat_status,
      is_active: payload.is_active,
      member_id: parseInt(payload.member_id),
    }).then((r2) => {
      const data = r2.data;
      this.$store.commit("member/updateFieldMember", {
        id: id,
        field: {
          ...data,
        },
      });
      return r2;
    });
  }

  users() {
    return this.get("/users").then((res) => {
      const data = res.data.map((item) => {
        return new UsersModel(item);
      });

      this.$store.commit("member/setUserList", data);
      return {
        ...res,
        data,
      };
    });
  }

  resend_invite(invitationID) {
    return this.get(`/invitation/${invitationID}/resend`).then((res) => {
      const data = res.data;

      // this.$store.commit("invitation/setList", data);

      return {
        ...res,
        data,
      };
    });
  }

  save(payload) {
    return this.post("/invitation", payload).then((res) => {
      const data = res.data;

      this.$store.commit("invitation/addNew", data);
      return res;
    });
  }

  drop(memberId) {
    return this.delete(`/users/${memberId}`).then((r2) => {
      console.log(r2);
      this.$store.commit("member/removemember", memberId);
      return r2;
    });
  }
}

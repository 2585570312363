import { SETTING } from "../enums";
import BaseModel from "./BaseModel";

export class SettingtModel extends BaseModel {
  get schema() {
    return {
      [SETTING.PRICING]: new PricingFormula(),
      [SETTING.BOOKING]: new BookingMargin(),
      [SETTING.INVITATION]: new Invitation(),
      [SETTING.TOKEN]: new TokenGeneration(),
      [SETTING.TERMSANDCONDITION]: new TermsAndCondition(),
      [SETTING.INFORMATIONPOPUP]: new InformationPopup(),
      [SETTING.POLICY]: new Policy(),
      [SETTING.TEXT]: new Text(),
      [SETTING.OTHER]: new Other(),
    };
  }
}

export class PricingFormula {
  block_time;
  speed;
  price_per_minute;
  ferry_discount;
  landing_fees;
  waiting_time_free;
  waiting_price_per_hour;
  waiting_price_24_hour;
}

export class TermsAndCondition {
  content;
}
export class Policy {
  content;
}

export class BookingMargin {
  time_between_bookings;
  time_after_immediate_take_off;
  max_duration_immediate_take_off;
  default_immediate_take_off_boarding_time;
  plane_rest_time;
}

export class Invitation {
  amount_of_invitations_when_joining;
  amount_of_additional_invitations_if_invite_become_customer;
  max_account_of_shadow_accounts;
  invite_message;
  shadow_invite_message;
}

export class TokenGeneration {
  referral_flight_hour;
  days_referral_generate_tokens;
  own_flight_hours;
  campaign_start_date;
  campaign_end_date;
  campaign_start_time;
  campaign_end_time;
}

export class InformationPopup {
  information_popup_start_date;
  information_popup_end_date;
  information_popup_start_time;
  information_popup_end_time;
  information_popup_text;
}
export class Text {
  terms_and_condition;
  privacy_policy;
  information_popup;
}

export class Other {
  uploads = {
    safety_cards: undefined,
  };
}

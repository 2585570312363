import { LocalStorageService } from "../local-storage.service";

export class JWTService {
  static localStorageService = new LocalStorageService();

  static getToken() {
    const sess = this.localStorageService.get("sess");
    return sess;
  }

  static setToken(accessToken) {
    this.localStorageService.set("sess", accessToken);
  }

  static get bearerToken() {
    const sess = this.getToken();
    return (sess && `Bearer ${sess.token}`) || null;
  }
}

import BaseModel from "./BaseModel";

export class InvitationModel extends BaseModel {
  get schema() {
    return {
      id: undefined,
      name: undefined,
      phone: undefined,
      invited_by: undefined,
      date: undefined,
      sent_by: undefined,
      member_display_id: undefined,
    };
  }
}

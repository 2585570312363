<template>
  <b-popover
    ref="popover"
    :show.sync="activeState"
    :target="target"
    :custom-class="customClass"
    @hidden="onHidden"
    @shown="onShown"
  >
    <template #title><slot name="title"></slot></template>
    <slot></slot>
  </b-popover>
</template>

<script>
export default {
  name: "BasePoperover",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeState: false,
    };
  },
  watch: {
    show(newVal, _oldVal) {
      this.activeState = newVal;
      if (newVal === false) {
        this.$emit("close");
      }
    },
  },
  methods: {
    onHidden() {
      this.$emit("hidden");
    },
    onShown() {
      this.$emit("shown");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .popover {
    &.b-popover {
      max-width: 360px !important;
    }
  }
}
</style>

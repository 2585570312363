<template>
  <base-input :label="label">
    <flat-picker
      ref="test"
      v-model="model"
      slot-scope="{ focus, blur }"
      :mode="mode"
      :no-calendar="noCalendar"
      date-format="Y-m-d"
      :placeholder="placeholder"
      :class="readonly ? '' : 'enabled'"
      :click-opens="false"
      :config="config"
      class="form-control datepicker"
      @on-open="focus"
      @on-close="blur"
      @on-change="onChange($event)"
    ></flat-picker>
  </base-input>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
import { DateTime } from "@/core/utils";

export default {
  name: "BaseDatepicker",
  components: {
    flatPicker,
  },
  props: {
    optSel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "single",
    },
    noCalendar: {
      type: String,
      default: "false",
    },
    dateFormat: {
      type: String,
      default: "Y-m-d  H:i",
    },
    enableHrs: {
      type: Boolean,
      default: false,
    },
    enableTime: {
      type: String,
      default: "true",
    },
    cast: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    config() {
      const opt = {
        mode: this.mode,
        noCalendar: this.noCalendar,
        enableTime: this.enableTime,
        dateFormat: this.dateFormat,
        altFormat: "F j, Y h:i",
        time_24hr: this.enableHrs,
        clickOpens: this.readonly ? false : true,
        // appendTo: this.ctrlref,
        /* plugins: [
          new ConfirmDatePlugin({ confirmText: "Done", showAlways: true }),
        ], */
        // static: true,
        // wrap: false,
      };

      if (!this.cast) {
        // let timeZone = 'America/Los_Angeles';
        opt.parseDate = (dateString, _format) => {
          let timezonedDate = DateTime.moment.parseZone(
            dateString,
            "YYYY-MM-DD HH:mm"
          );
          return new Date(
            timezonedDate.year(),
            timezonedDate.month(),
            timezonedDate.date(),
            timezonedDate.hour(),
            timezonedDate.minute()
          );
        };
        opt.formatDate = (dateString, _format) => {
          return DateTime.format(dateString, "YYYY-MM-DD HH:mm");
        };
      }

      return opt;
    },
  },
  methods: {
    onChange(event) {
      if (this.optSel) {
        this.$refs.test.fp.close();
      }
      this.$emit("change", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker {
  &.enabled {
    background-color: #fff;
  }
}
</style>
